@import './assets/css/core-icons.css';
@import './assets/css/pixellove-icons.css';
/*
  Bull (latin extended)
  find hosted fonts at 'https://www.redbull.com/fonts/rb/bull/v3/latin/…'
 */
@font-face {
  font-family: 'Bull';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/css/fonts/bull/Bull-Regular.woff2) format('woff2'),
  url(./assets/css/fonts/bull/Bull-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Bull';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./assets/css/fonts/bull/Bull-Medium.woff2) format('woff2'),
  url(./assets/css/fonts/bull/Bull-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Bull';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/css/fonts/bull/Bull-Bold.woff2) format('woff2'),
  url(./assets/css/fonts/bull/Bull-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Bull';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(./assets/css/fonts/bull/Bull-Heavy1.woff2) format('woff2'),
  url(./assets/css/fonts/bull/Bull-Heavy1.woff) format('woff');
}

/*
  Bull Text (latin extended)
  find hosted fonts at 'https://www.redbull.com/fonts/rb/bull-text/v1/latin/'
 */
@font-face {
  font-family: 'BullText';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(./assets/css/fonts/bull/BullText-Regular.woff2) format('woff2'),
  url(./assets/css/fonts/bull/BullText-Regular.woff) format('woff');
}

@font-face {
  font-family: 'BullText';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(./assets/css/fonts/bull/BullText-Bold.woff2) format('woff2'),
  url(./assets/css/fonts/bull/BullText-Bold.woff) format('woff');
}

/*
  Red Bull's core set of icons
 */
@font-face {
  font-family: 'ios-infinity';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/css/iconfont/ios-infinity.woff2) format('woff2'),
  url(./assets/css/iconfont/ios-infinity.woff) format('woff');
}

/*
  Red Bull's pixellove set of icons
 */
@font-face {
  font-family: 'pixellove';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/css/iconfont/pixellove.woff2) format('woff2'),
  url(./assets/css/iconfont/pixellove.woff) format('woff');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Bull', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f6f6;
  width: 100%;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input {
  height: 30px;
}

.p-component-overlay.p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.4);
}

.ios-infinity-icon {
  font-family: 'ios-infinity', sans-serif;
}

.pixellove-icon {
  font-family: 'pixellove', sans-serif;
}

#root {
  height: 100%;
}