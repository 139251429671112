/* Converted Red Bull's icons scss to css */

.icon-arr-cross-small:before {
  content: "\f101";
}
.icon-arr-cross:before {
  content: "\f102";
}
.icon-arr-diagonal-2-small:before {
  content: "\f103";
}
.icon-arr-diagonal-2:before {
  content: "\f104";
}
.icon-arr-diagonal-small:before {
  content: "\f105";
}
.icon-arr-diagonal:before {
  content: "\f106";
}
.icon-arr-down-small:before {
  content: "\f107";
}
.icon-arr-down:before {
  content: "\f108";
}
.icon-arr-download-small:before {
  content: "\f109";
}
.icon-arr-download:before {
  content: "\f10a";
}
.icon-arr-forward-arrow-small:before {
  content: "\f10b";
}
.icon-arr-forward-arrow:before {
  content: "\f10c";
}
.icon-arr-horizontal-arrows-small:before {
  content: "\f10d";
}
.icon-arr-horizontal-arrows:before {
  content: "\f10e";
}
.icon-arr-horizontal-small:before {
  content: "\f10f";
}
.icon-arr-horizontal:before {
  content: "\f110";
}
.icon-arr-in-2-small:before {
  content: "\f111";
}
.icon-arr-in-2:before {
  content: "\f112";
}
.icon-arr-in-angle-small:before {
  content: "\f113";
}
.icon-arr-in-angle:before {
  content: "\f114";
}
.icon-arr-left-small:before {
  content: "\f115";
}
.icon-arr-left:before {
  content: "\f116";
}
.icon-arr-out-2-small:before {
  content: "\f117";
}
.icon-arr-out-2:before {
  content: "\f118";
}
.icon-arr-reset-small:before {
  content: "\f119";
}
.icon-arr-reset:before {
  content: "\f11a";
}
.icon-arr-right-small:before {
  content: "\f11b";
}
.icon-arr-right:before {
  content: "\f11c";
}
.icon-arr-round-out-small:before {
  content: "\f11d";
}
.icon-arr-round-out:before {
  content: "\f11e";
}
.icon-arr-syncing-small:before {
  content: "\f11f";
}
.icon-arr-syncing:before {
  content: "\f120";
}
.icon-arr-up-small:before {
  content: "\f121";
}
.icon-arr-up:before {
  content: "\f122";
}
.icon-arr-upload-small:before {
  content: "\f123";
}
.icon-arr-upload:before {
  content: "\f124";
}
.icon-arr-vertical-arrows-small:before {
  content: "\f125";
}
.icon-arr-vertical-arrows:before {
  content: "\f126";
}
.icon-arr-vertical-small:before {
  content: "\f127";
}
.icon-arr-vertical:before {
  content: "\f128";
}
.icon-back-double-small:before {
  content: "\f129";
}
.icon-back-double:before {
  content: "\f12a";
}
.icon-back-small:before {
  content: "\f12b";
}
.icon-back:before {
  content: "\f12c";
}
.icon-des-pencil-small:before {
  content: "\f12d";
}
.icon-des-pencil:before {
  content: "\f12e";
}
.icon-dots-vertical:before {
  content: "\f12f";
}
.icon-down-small:before {
  content: "\f130";
}
.icon-down:before {
  content: "\f131";
}
.icon-drop-file-small:before {
  content: "\f132";
}
.icon-drop-file:before {
  content: "\f133";
}
.icon-gen-information-small:before {
  content: "\f134";
}
.icon-gen-information:before {
  content: "\f135";
}
.icon-gen-warning-small:before {
  content: "\f136";
}
.icon-gen-warning:before {
  content: "\f137";
}
.icon-loc-pin-angle-small:before {
  content: "\f138";
}
.icon-loc-pin-angle:before {
  content: "\f139";
}
.icon-loc-pin-small:before {
  content: "\f13a";
}
.icon-loc-pin:before {
  content: "\f13b";
}
.icon-loc-search-minus-small:before {
  content: "\f13c";
}
.icon-loc-search-minus:before {
  content: "\f13d";
}
.icon-loc-search-plus-small:before {
  content: "\f13e";
}
.icon-loc-search-plus:before {
  content: "\f13f";
}
.icon-next-double-small:before {
  content: "\f140";
}
.icon-next-double:before {
  content: "\f141";
}
.icon-next-small:before {
  content: "\f142";
}
.icon-next:before {
  content: "\f143";
}
.icon-plus-small:before {
  content: "\f144";
}
.icon-plus:before {
  content: "\f145";
}
.icon-search-small:before {
  content: "\f146";
}
.icon-search:before {
  content: "\f147";
}
.icon-tim-calendar-small:before {
  content: "\f148";
}
.icon-tim-calendar:before {
  content: "\f149";
}
.icon-ui-cross-small:before {
  content: "\f14a";
}
.icon-ui-cross:before {
  content: "\f14b";
}
.icon-ui-tick-small:before {
  content: "\f14c";
}
.icon-ui-tick:before {
  content: "\f14d";
}
.icon-up-small:before {
  content: "\f14e";
}
.icon-up:before {
  content: "\f14f";
}
.icon-bra-android-small:before {
  content: "\f150";
}
.icon-bra-android:before {
  content: "\f151";
}
.icon-bra-apple-small:before {
  content: "\f152";
}
.icon-bra-apple:before {
  content: "\f153";
}
.icon-bra-email-small:before {
  content: "\f154";
}
.icon-bra-email:before {
  content: "\f155";
}
.icon-bra-windows-small:before {
  content: "\f156";
}
.icon-bra-windows:before {
  content: "\f157";
}
.icon-cel-heart-small:before {
  content: "\f158";
}
.icon-cel-heart:before {
  content: "\f159";
}
.icon-cha-exclamation-small:before {
  content: "\f15a";
}
.icon-cha-exclamation:before {
  content: "\f15b";
}
.icon-cha-minus-small:before {
  content: "\f15c";
}
.icon-cha-minus:before {
  content: "\f15d";
}
.icon-cha-plus-small:before {
  content: "\f15e";
}
.icon-cha-plus:before {
  content: "\f15f";
}
.icon-cha-question-mark-small:before {
  content: "\f160";
}
.icon-cha-question-mark:before {
  content: "\f161";
}
.icon-cle-dustbin-small:before {
  content: "\f162";
}
.icon-cle-dustbin:before {
  content: "\f163";
}
.icon-com-disk-small:before {
  content: "\f164";
}
.icon-com-disk:before {
  content: "\f165";
}
.icon-com-printer-small:before {
  content: "\f166";
}
.icon-com-printer:before {
  content: "\f167";
}
.icon-com-tablet:before {
  content: "\f168";
}
.icon-con-hammer-screwdriver-2:before {
  content: "\f169";
}
.icon-con-protractor-small:before {
  content: "\f16a";
}
.icon-con-protractor:before {
  content: "\f16b";
}
.icon-con-ruler-pencil:before {
  content: "\f16c";
}
.icon-con-set-square-small:before {
  content: "\f16d";
}
.icon-con-set-square:before {
  content: "\f16e";
}
.icon-con-spanner-small:before {
  content: "\f16f";
}
.icon-con-spanner:before {
  content: "\f170";
}
.icon-doc-cut:before {
  content: "\f171";
}
.icon-doc-document-small:before {
  content: "\f172";
}
.icon-doc-document:before {
  content: "\f173";
}
.icon-doc-documents-small:before {
  content: "\f174";
}
.icon-doc-documents:before {
  content: "\f175";
}
.icon-ema-email-small:before {
  content: "\f176";
}
.icon-ema-email:before {
  content: "\f177";
}
.icon-gen-alarm-off-small:before {
  content: "\f178";
}
.icon-gen-alarm-off:before {
  content: "\f179";
}
.icon-gen-alarm-small:before {
  content: "\f17a";
}
.icon-gen-alarm:before {
  content: "\f17b";
}
.icon-gen-cog-small:before {
  content: "\f17c";
}
.icon-gen-cog:before {
  content: "\f17d";
}
.icon-gen-filter-small:before {
  content: "\f17e";
}
.icon-gen-filter:before {
  content: "\f17f";
}
.icon-gen-flag-2-small:before {
  content: "\f180";
}
.icon-gen-flag-2:before {
  content: "\f181";
}
.icon-gen-home-small:before {
  content: "\f182";
}
.icon-gen-home:before {
  content: "\f183";
}
.icon-loc-arrow-small:before {
  content: "\f184";
}
.icon-loc-arrow:before {
  content: "\f185";
}
.icon-loc-map-route:before {
  content: "\f186";
}
.icon-loc-point-small:before {
  content: "\f187";
}
.icon-loc-point:before {
  content: "\f188";
}
.icon-tel-bluetooth-small:before {
  content: "\f189";
}
.icon-tel-bluetooth:before {
  content: "\f18a";
}
.icon-tel-fixed-handset-small:before {
  content: "\f18b";
}
.icon-tel-fixed-handset:before {
  content: "\f18c";
}
.icon-tel-phone-2:before {
  content: "\f18d";
}
.icon-tel-phone-mac:before {
  content: "\f18e";
}
.icon-tim-calendar-month-small:before {
  content: "\f18f";
}
.icon-tim-calendar-month:before {
  content: "\f190";
}
.icon-tim-clock-small:before {
  content: "\f191";
}
.icon-tim-clock:before {
  content: "\f192";
}
.icon-ui-cross-2-small:before {
  content: "\f193";
}
.icon-ui-cross-2:before {
  content: "\f194";
}
.icon-ui-signout:before {
  content: "\f195";
}
.icon-ui-tick-2-small:before {
  content: "\f196";
}
.icon-ui-tick-2:before {
  content: "\f197";
}
.icon-use-user:before {
  content: "\f198";
}
.icon-wea-cloud-small:before {
  content: "\f199";
}
.icon-wea-cloud:before {
  content: "\f19a";
}
