.i-arr-3-rotate:before { content: "\f101"; }
.i-arr-4-rotate:before { content: "\f102"; }
.i-arr-axis-angle-rotate:before { content: "\f103"; }
.i-arr-axis-angle:before { content: "\f104"; }
.i-arr-axis-rotate:before { content: "\f105"; }
.i-arr-axis:before { content: "\f106"; }
.i-arr-bend:before { content: "\f107"; }
.i-arr-converge-2:before { content: "\f108"; }
.i-arr-converge-3:before { content: "\f109"; }
.i-arr-converge:before { content: "\f10a"; }
.i-arr-cross:before { content: "\f10b"; }
.i-arr-diagonal-2:before { content: "\f10c"; }
.i-arr-diagonal:before { content: "\f10d"; }
.i-arr-diverge-3:before { content: "\f10e"; }
.i-arr-download:before { content: "\f10f"; }
.i-arr-elipse-angle:before { content: "\f110"; }
.i-arr-elipse:before { content: "\f111"; }
.i-arr-ellipse-angle-rotate:before { content: "\f112"; }
.i-arr-ellipse-rotate:before { content: "\f113"; }
.i-arr-first-exit:before { content: "\f114"; }
.i-arr-forward-arrow:before { content: "\f115"; }
.i-arr-fourth-exit:before { content: "\f116"; }
.i-arr-horizontal-2:before { content: "\f117"; }
.i-arr-horizontal-arrows:before { content: "\f118"; }
.i-arr-horizontal:before { content: "\f119"; }
.i-arr-in-2:before { content: "\f11a"; }
.i-arr-in-3:before { content: "\f11b"; }
.i-arr-in-angle:before { content: "\f11c"; }
.i-arr-in:before { content: "\f11d"; }
.i-arr-jagged:before { content: "\f11e"; }
.i-arr-loop:before { content: "\f11f"; }
.i-arr-out-2:before { content: "\f120"; }
.i-arr-out:before { content: "\f121"; }
.i-arr-point-angle:before { content: "\f122"; }
.i-arr-point:before { content: "\f123"; }
.i-arr-redo-arrow:before { content: "\f124"; }
.i-arr-repeat:before { content: "\f125"; }
.i-arr-reset:before { content: "\f126"; }
.i-arr-reverse-arrow:before { content: "\f127"; }
.i-arr-rotate:before { content: "\f128"; }
.i-arr-round-out:before { content: "\f129"; }
.i-arr-second-exit:before { content: "\f12a"; }
.i-arr-shuffle:before { content: "\f12b"; }
.i-arr-spiral:before { content: "\f12c"; }
.i-arr-split-2:before { content: "\f12d"; }
.i-arr-split:before { content: "\f12e"; }
.i-arr-stagger:before { content: "\f12f"; }
.i-arr-syncing:before { content: "\f130"; }
.i-arr-third-exit:before { content: "\f131"; }
.i-arr-turn-in:before { content: "\f132"; }
.i-arr-turn-off:before { content: "\f133"; }
.i-arr-undo-arrow:before { content: "\f134"; }
.i-arr-upload:before { content: "\f135"; }
.i-arr-vertical-2:before { content: "\f136"; }
.i-arr-vertical-arrows:before { content: "\f137"; }
.i-arr-vertical:before { content: "\f138"; }
.i-arr-wiggle:before { content: "\f139"; }
.i-bra-android:before { content: "\f13a"; }
.i-bra-apple:before { content: "\f13b"; }
.i-bra-bebo:before { content: "\f13c"; }
.i-bra-behance:before { content: "\f13d"; }
.i-bra-blackberry:before { content: "\f13e"; }
.i-bra-blogger:before { content: "\f13f"; }
.i-bra-deezer:before { content: "\f140"; }
.i-bra-delicious:before { content: "\f141"; }
.i-bra-deviantart:before { content: "\f142"; }
.i-bra-digg:before { content: "\f143"; }
.i-bra-dribbble:before { content: "\f144"; }
.i-bra-email:before { content: "\f145"; }
.i-bra-evernote:before { content: "\f146"; }
.i-bra-facebook:before { content: "\f147"; }
.i-bra-flickr:before { content: "\f148"; }
.i-bra-forrst:before { content: "\f149"; }
.i-bra-foursquare:before { content: "\f14a"; }
.i-bra-github:before { content: "\f14b"; }
.i-bra-google:before { content: "\f14c"; }
.i-bra-instagram:before { content: "\f14d"; }
.i-bra-lastfm:before { content: "\f14e"; }
.i-bra-linkedin:before { content: "\f14f"; }
.i-bra-myspace:before { content: "\f150"; }
.i-bra-paypal:before { content: "\f151"; }
.i-bra-picasa:before { content: "\f152"; }
.i-bra-pinterest:before { content: "\f153"; }
.i-bra-reddit:before { content: "\f154"; }
.i-bra-rss:before { content: "\f155"; }
.i-bra-sharethis:before { content: "\f156"; }
.i-bra-skype:before { content: "\f157"; }
.i-bra-spotify:before { content: "\f158"; }
.i-bra-stumbleupon:before { content: "\f159"; }
.i-bra-tumblr:before { content: "\f15a"; }
.i-bra-twitter:before { content: "\f15b"; }
.i-bra-vimeo:before { content: "\f15c"; }
.i-bra-vine:before { content: "\f15d"; }
.i-bra-windows:before { content: "\f15e"; }
.i-bra-wordpress:before { content: "\f15f"; }
.i-bra-yelp:before { content: "\f160"; }
.i-bra-youtube:before { content: "\f161"; }
.i-cel-angel:before { content: "\f162"; }
.i-cel-balloon-love:before { content: "\f163"; }
.i-cel-balloons:before { content: "\f164"; }
.i-cel-banner:before { content: "\f165"; }
.i-cel-bauble-2:before { content: "\f166"; }
.i-cel-bauble-3:before { content: "\f167"; }
.i-cel-bauble-star:before { content: "\f168"; }
.i-cel-bauble:before { content: "\f169"; }
.i-cel-baubles:before { content: "\f16a"; }
.i-cel-bell:before { content: "\f16b"; }
.i-cel-bible:before { content: "\f16c"; }
.i-cel-bonfire:before { content: "\f16d"; }
.i-cel-book-love:before { content: "\f16e"; }
.i-cel-bow:before { content: "\f16f"; }
.i-cel-bunny:before { content: "\f170"; }
.i-cel-bunting:before { content: "\f171"; }
.i-cel-cake-birthday:before { content: "\f172"; }
.i-cel-cake-wedding:before { content: "\f173"; }
.i-cel-candle:before { content: "\f174"; }
.i-cel-candles:before { content: "\f175"; }
.i-cel-candy-cane:before { content: "\f176"; }
.i-cel-cheers:before { content: "\f177"; }
.i-cel-church:before { content: "\f178"; }
.i-cel-couple-love:before { content: "\f179"; }
.i-cel-cracker:before { content: "\f17a"; }
.i-cel-cross:before { content: "\f17b"; }
.i-cel-crosses:before { content: "\f17c"; }
.i-cel-date-14:before { content: "\f17d"; }
.i-cel-date-25:before { content: "\f17e"; }
.i-cel-dress-prom:before { content: "\f17f"; }
.i-cel-egg-basket:before { content: "\f180"; }
.i-cel-egg-easter-2:before { content: "\f181"; }
.i-cel-egg-easter:before { content: "\f182"; }
.i-cel-engagement:before { content: "\f183"; }
.i-cel-firework:before { content: "\f184"; }
.i-cel-fireworks:before { content: "\f185"; }
.i-cel-flower-heart:before { content: "\f186"; }
.i-cel-gingerbread:before { content: "\f187"; }
.i-cel-hanukkah:before { content: "\f188"; }
.i-cel-hat-santa-2:before { content: "\f189"; }
.i-cel-hat-santa:before { content: "\f18a"; }
.i-cel-hat:before { content: "\f18b"; }
.i-cel-heart-arrow:before { content: "\f18c"; }
.i-cel-heart-broken:before { content: "\f18d"; }
.i-cel-heart-line:before { content: "\f18e"; }
.i-cel-heart-lock:before { content: "\f18f"; }
.i-cel-heart:before { content: "\f190"; }
.i-cel-hearts:before { content: "\f191"; }
.i-cel-holly-2:before { content: "\f192"; }
.i-cel-holly:before { content: "\f193"; }
.i-cel-letter-christmas:before { content: "\f194"; }
.i-cel-letter-love-2:before { content: "\f195"; }
.i-cel-letter-love-3:before { content: "\f196"; }
.i-cel-letter-love:before { content: "\f197"; }
.i-cel-party-balloon:before { content: "\f198"; }
.i-cel-party-hat:before { content: "\f199"; }
.i-cel-plum-pudding:before { content: "\f19a"; }
.i-cel-present:before { content: "\f19b"; }
.i-cel-qupid-arrow:before { content: "\f19c"; }
.i-cel-reindeer:before { content: "\f19d"; }
.i-cel-rings-love:before { content: "\f19e"; }
.i-cel-rose:before { content: "\f19f"; }
.i-cel-sleigh:before { content: "\f1a0"; }
.i-cel-snow-globe:before { content: "\f1a1"; }
.i-cel-snowman:before { content: "\f1a2"; }
.i-cel-star:before { content: "\f1a3"; }
.i-cel-stars:before { content: "\f1a4"; }
.i-cel-stocking-line:before { content: "\f1a5"; }
.i-cel-stocking:before { content: "\f1a6"; }
.i-cel-streamers:before { content: "\f1a7"; }
.i-cel-sweet:before { content: "\f1a8"; }
.i-cel-tiara:before { content: "\f1a9"; }
.i-cel-tree-star:before { content: "\f1aa"; }
.i-cel-tree:before { content: "\f1ab"; }
.i-cha-0:before { content: "\f1ac"; }
.i-cha-1:before { content: "\f1ad"; }
.i-cha-2:before { content: "\f1ae"; }
.i-cha-3:before { content: "\f1af"; }
.i-cha-4:before { content: "\f1b0"; }
.i-cha-5:before { content: "\f1b1"; }
.i-cha-6:before { content: "\f1b2"; }
.i-cha-7:before { content: "\f1b3"; }
.i-cha-8:before { content: "\f1b4"; }
.i-cha-9:before { content: "\f1b5"; }
.i-cha-a:before { content: "\f1b6"; }
.i-cha-ampersand:before { content: "\f1b7"; }
.i-cha-approximately:before { content: "\f1b8"; }
.i-cha-asterisk:before { content: "\f1b9"; }
.i-cha-at:before { content: "\f1ba"; }
.i-cha-b:before { content: "\f1bb"; }
.i-cha-brackets:before { content: "\f1bc"; }
.i-cha-c:before { content: "\f1bd"; }
.i-cha-curly-brackets:before { content: "\f1be"; }
.i-cha-d:before { content: "\f1bf"; }
.i-cha-divide-slash:before { content: "\f1c0"; }
.i-cha-divide:before { content: "\f1c1"; }
.i-cha-e:before { content: "\f1c2"; }
.i-cha-equals:before { content: "\f1c3"; }
.i-cha-exclamation:before { content: "\f1c4"; }
.i-cha-f:before { content: "\f1c5"; }
.i-cha-g:before { content: "\f1c6"; }
.i-cha-h:before { content: "\f1c7"; }
.i-cha-i:before { content: "\f1c8"; }
.i-cha-j:before { content: "\f1c9"; }
.i-cha-k:before { content: "\f1ca"; }
.i-cha-l:before { content: "\f1cb"; }
.i-cha-less-than-or-equal:before { content: "\f1cc"; }
.i-cha-less-than:before { content: "\f1cd"; }
.i-cha-m:before { content: "\f1ce"; }
.i-cha-minus:before { content: "\f1cf"; }
.i-cha-more-than-or-equal:before { content: "\f1d0"; }
.i-cha-more-than:before { content: "\f1d1"; }
.i-cha-multiply:before { content: "\f1d2"; }
.i-cha-n:before { content: "\f1d3"; }
.i-cha-not-equal-to:before { content: "\f1d4"; }
.i-cha-number:before { content: "\f1d5"; }
.i-cha-o:before { content: "\f1d6"; }
.i-cha-p:before { content: "\f1d7"; }
.i-cha-percentage:before { content: "\f1d8"; }
.i-cha-plus-minus:before { content: "\f1d9"; }
.i-cha-plus:before { content: "\f1da"; }
.i-cha-q:before { content: "\f1db"; }
.i-cha-question-mark:before { content: "\f1dc"; }
.i-cha-r:before { content: "\f1dd"; }
.i-cha-s:before { content: "\f1de"; }
.i-cha-square-brackets:before { content: "\f1df"; }
.i-cha-square-root:before { content: "\f1e0"; }
.i-cha-t:before { content: "\f1e1"; }
.i-cha-u:before { content: "\f1e2"; }
.i-cha-v:before { content: "\f1e3"; }
.i-cha-w:before { content: "\f1e4"; }
.i-cha-x:before { content: "\f1e5"; }
.i-cha-y:before { content: "\f1e6"; }
.i-cha-z:before { content: "\f1e7"; }
.i-cha-bubble-female:before { content: "\f1e8"; }
.i-cha-bubble-male:before { content: "\f1e9"; }
.i-cha-bubbles-three:before { content: "\f1ea"; }
.i-cha-bubbles-two:before { content: "\f1eb"; }
.i-cha-cloud:before { content: "\f1ec"; }
.i-cha-loudspeakers:before { content: "\f1ed"; }
.i-cha-megaphone:before { content: "\f1ee"; }
.i-cha-q-a:before { content: "\f1ef"; }
.i-cha-quote:before { content: "\f1f0"; }
.i-cha-quotes:before { content: "\f1f1"; }
.i-cha-rect-conversation:before { content: "\f1f2"; }
.i-cha-rect-cross:before { content: "\f1f3"; }
.i-cha-rect-flag:before { content: "\f1f4"; }
.i-cha-rect-love:before { content: "\f1f5"; }
.i-cha-rect-minus:before { content: "\f1f6"; }
.i-cha-rect-movie:before { content: "\f1f7"; }
.i-cha-rect-music:before { content: "\f1f8"; }
.i-cha-rect-person:before { content: "\f1f9"; }
.i-cha-rect-plus:before { content: "\f1fa"; }
.i-cha-rect-quote:before { content: "\f1fb"; }
.i-cha-rect-reply:before { content: "\f1fc"; }
.i-cha-rect-search:before { content: "\f1fd"; }
.i-cha-rect-smiley:before { content: "\f1fe"; }
.i-cha-rect-star:before { content: "\f1ff"; }
.i-cha-rect-swear:before { content: "\f200"; }
.i-cha-rect-text:before { content: "\f201"; }
.i-cha-rect-tick:before { content: "\f202"; }
.i-cha-rect-warning:before { content: "\f203"; }
.i-cha-rectangle:before { content: "\f204"; }
.i-cha-round-conversation:before { content: "\f205"; }
.i-cha-round:before { content: "\f206"; }
.i-cha-rounded:before { content: "\f207"; }
.i-cha-shout:before { content: "\f208"; }
.i-cha-talk-female:before { content: "\f209"; }
.i-cha-talk-male:before { content: "\f20a"; }
.i-cha-talk:before { content: "\f20b"; }
.i-cha-thought:before { content: "\f20c"; }
.i-cha-translate-2:before { content: "\f20d"; }
.i-cha-translate:before { content: "\f20e"; }
.i-cha-wiggly:before { content: "\f20f"; }
.i-cle-bin:before { content: "\f210"; }
.i-cle-bottle-2:before { content: "\f211"; }
.i-cle-bottle:before { content: "\f212"; }
.i-cle-brush-2:before { content: "\f213"; }
.i-cle-brush:before { content: "\f214"; }
.i-cle-bucket-mop:before { content: "\f215"; }
.i-cle-dispensers:before { content: "\f216"; }
.i-cle-dry-cool:before { content: "\f217"; }
.i-cle-dry-hot:before { content: "\f218"; }
.i-cle-dry-no:before { content: "\f219"; }
.i-cle-dry-warm:before { content: "\f21a"; }
.i-cle-dustbin:before { content: "\f21b"; }
.i-cle-dustpan-brush-2:before { content: "\f21c"; }
.i-cle-dustpan-brush:before { content: "\f21d"; }
.i-cle-iron-board:before { content: "\f21e"; }
.i-cle-iron-cool:before { content: "\f21f"; }
.i-cle-iron-hot:before { content: "\f220"; }
.i-cle-iron-no:before { content: "\f221"; }
.i-cle-iron-steam-no:before { content: "\f222"; }
.i-cle-iron-steam:before { content: "\f223"; }
.i-cle-iron-warm:before { content: "\f224"; }
.i-cle-kitchen-roll:before { content: "\f225"; }
.i-cle-laundry-basket:before { content: "\f226"; }
.i-cle-laundry:before { content: "\f227"; }
.i-cle-maid:before { content: "\f228"; }
.i-cle-mop:before { content: "\f229"; }
.i-cle-peg:before { content: "\f22a"; }
.i-cle-pegs:before { content: "\f22b"; }
.i-cle-plunger-brush:before { content: "\f22c"; }
.i-cle-plunger:before { content: "\f22d"; }
.i-cle-pressure-washer:before { content: "\f22e"; }
.i-cle-scrubbing-brush:before { content: "\f22f"; }
.i-cle-soap-bar:before { content: "\f230"; }
.i-cle-soap-liquid:before { content: "\f231"; }
.i-cle-sponge-2:before { content: "\f232"; }
.i-cle-sponge:before { content: "\f233"; }
.i-cle-spray:before { content: "\f234"; }
.i-cle-spraycan:before { content: "\f235"; }
.i-cle-squeegee:before { content: "\f236"; }
.i-cle-tap:before { content: "\f237"; }
.i-cle-toilet-brush:before { content: "\f238"; }
.i-cle-toilet-roll:before { content: "\f239"; }
.i-cle-towel:before { content: "\f23a"; }
.i-cle-vaccum-bagless:before { content: "\f23b"; }
.i-cle-vacuum-floor:before { content: "\f23c"; }
.i-cle-vacuum-hand:before { content: "\f23d"; }
.i-cle-vacuum:before { content: "\f23e"; }
.i-cle-wash-basin:before { content: "\f23f"; }
.i-cle-wash-cool:before { content: "\f240"; }
.i-cle-wash-hot:before { content: "\f241"; }
.i-cle-wash-line:before { content: "\f242"; }
.i-cle-wash-no:before { content: "\f243"; }
.i-cle-wash-warm:before { content: "\f244"; }
.i-cle-wash:before { content: "\f245"; }
.i-cle-wastebin:before { content: "\f246"; }
.i-cle-water:before { content: "\f247"; }
.i-cle-wheelie-bin-open:before { content: "\f248"; }
.i-cle-wheelie-bin:before { content: "\f249"; }
.i-clo-basque:before { content: "\f24a"; }
.i-clo-beanie:before { content: "\f24b"; }
.i-clo-belt:before { content: "\f24c"; }
.i-clo-bobble-2:before { content: "\f24d"; }
.i-clo-bobble:before { content: "\f24e"; }
.i-clo-boot:before { content: "\f24f"; }
.i-clo-bowler:before { content: "\f250"; }
.i-clo-boxers:before { content: "\f251"; }
.i-clo-bra-panties:before { content: "\f252"; }
.i-clo-bra:before { content: "\f253"; }
.i-clo-briefs:before { content: "\f254"; }
.i-clo-camisole:before { content: "\f255"; }
.i-clo-cap:before { content: "\f256"; }
.i-clo-converse:before { content: "\f257"; }
.i-clo-dress-2:before { content: "\f258"; }
.i-clo-dress:before { content: "\f259"; }
.i-clo-ear-muffs:before { content: "\f25a"; }
.i-clo-flip-flops:before { content: "\f25b"; }
.i-clo-glasses-bow:before { content: "\f25c"; }
.i-clo-glove:before { content: "\f25d"; }
.i-clo-hat-cowboy:before { content: "\f25e"; }
.i-clo-high-heels:before { content: "\f25f"; }
.i-clo-hiking:before { content: "\f260"; }
.i-clo-jacket:before { content: "\f261"; }
.i-clo-mannequin:before { content: "\f262"; }
.i-clo-mexican:before { content: "\f263"; }
.i-clo-mitten:before { content: "\f264"; }
.i-clo-no-heels:before { content: "\f265"; }
.i-clo-no-sneakers:before { content: "\f266"; }
.i-clo-panties-2:before { content: "\f267"; }
.i-clo-panties-3:before { content: "\f268"; }
.i-clo-panties:before { content: "\f269"; }
.i-clo-pants-2:before { content: "\f26a"; }
.i-clo-pants-3:before { content: "\f26b"; }
.i-clo-pants:before { content: "\f26c"; }
.i-clo-polo:before { content: "\f26d"; }
.i-clo-scarf:before { content: "\f26e"; }
.i-clo-screen:before { content: "\f26f"; }
.i-clo-shirt-folded:before { content: "\f270"; }
.i-clo-shirt:before { content: "\f271"; }
.i-clo-shoe:before { content: "\f272"; }
.i-clo-shoes:before { content: "\f273"; }
.i-clo-short-shirt:before { content: "\f274"; }
.i-clo-shorts-2:before { content: "\f275"; }
.i-clo-shorts:before { content: "\f276"; }
.i-clo-skirt:before { content: "\f277"; }
.i-clo-slipper:before { content: "\f278"; }
.i-clo-sneaker:before { content: "\f279"; }
.i-clo-sock-pop:before { content: "\f27a"; }
.i-clo-sock:before { content: "\f27b"; }
.i-clo-suit:before { content: "\f27c"; }
.i-clo-sun-hat:before { content: "\f27d"; }
.i-clo-suspender:before { content: "\f27e"; }
.i-clo-sweater:before { content: "\f27f"; }
.i-clo-t-female:before { content: "\f280"; }
.i-clo-t-hanger:before { content: "\f281"; }
.i-clo-t-long:before { content: "\f282"; }
.i-clo-t-sport:before { content: "\f283"; }
.i-clo-t:before { content: "\f284"; }
.i-clo-thong:before { content: "\f285"; }
.i-clo-tie:before { content: "\f286"; }
.i-clo-top:before { content: "\f287"; }
.i-clo-trilby:before { content: "\f288"; }
.i-clo-uggs-2:before { content: "\f289"; }
.i-clo-uggs:before { content: "\f28a"; }
.i-clo-v-neck:before { content: "\f28b"; }
.i-clo-vest-3:before { content: "\f28c"; }
.i-clo-vest-string:before { content: "\f28d"; }
.i-clo-vest:before { content: "\f28e"; }
.i-clo-waistcoat:before { content: "\f28f"; }
.i-clo-cabinet-2:before { content: "\f290"; }
.i-clo-cabinet-3:before { content: "\f291"; }
.i-clo-cabinet:before { content: "\f292"; }
.i-clo-cloud-code:before { content: "\f293"; }
.i-clo-cloud-computer-2:before { content: "\f294"; }
.i-clo-cloud-computer-network:before { content: "\f295"; }
.i-clo-cloud-computer:before { content: "\f296"; }
.i-clo-cloud-cross:before { content: "\f297"; }
.i-clo-cloud-download:before { content: "\f298"; }
.i-clo-cloud-folder:before { content: "\f299"; }
.i-clo-cloud-images:before { content: "\f29a"; }
.i-clo-cloud-laptop:before { content: "\f29b"; }
.i-clo-cloud-minus:before { content: "\f29c"; }
.i-clo-cloud-music:before { content: "\f29d"; }
.i-clo-cloud-network-folder:before { content: "\f29e"; }
.i-clo-cloud-network:before { content: "\f29f"; }
.i-clo-cloud-phone-2:before { content: "\f2a0"; }
.i-clo-cloud-phone:before { content: "\f2a1"; }
.i-clo-cloud-plus:before { content: "\f2a2"; }
.i-clo-cloud-protect:before { content: "\f2a3"; }
.i-clo-cloud-search:before { content: "\f2a4"; }
.i-clo-cloud-secure:before { content: "\f2a5"; }
.i-clo-cloud-settings:before { content: "\f2a6"; }
.i-clo-cloud-sync:before { content: "\f2a7"; }
.i-clo-cloud-tablet:before { content: "\f2a8"; }
.i-clo-cloud-tick:before { content: "\f2a9"; }
.i-clo-cloud-upload:before { content: "\f2aa"; }
.i-clo-cloud-warning:before { content: "\f2ab"; }
.i-clo-cloud-wifi-2:before { content: "\f2ac"; }
.i-clo-cloud-wifi:before { content: "\f2ad"; }
.i-clo-clouds:before { content: "\f2ae"; }
.i-clo-server-computer-network:before { content: "\f2af"; }
.i-clo-server-connections:before { content: "\f2b0"; }
.i-clo-server-cross:before { content: "\f2b1"; }
.i-clo-server-download:before { content: "\f2b2"; }
.i-clo-server-edit:before { content: "\f2b3"; }
.i-clo-server-minus:before { content: "\f2b4"; }
.i-clo-server-network:before { content: "\f2b5"; }
.i-clo-server-plus:before { content: "\f2b6"; }
.i-clo-server-protect:before { content: "\f2b7"; }
.i-clo-server-search:before { content: "\f2b8"; }
.i-clo-server-secure:before { content: "\f2b9"; }
.i-clo-server-settings:before { content: "\f2ba"; }
.i-clo-server-single:before { content: "\f2bb"; }
.i-clo-server-sync:before { content: "\f2bc"; }
.i-clo-server-tick:before { content: "\f2bd"; }
.i-clo-server-upload:before { content: "\f2be"; }
.i-clo-server-warning:before { content: "\f2bf"; }
.i-clo-server:before { content: "\f2c0"; }
.i-clo-servers-network:before { content: "\f2c1"; }
.i-com-10:before { content: "\f2c2"; }
.i-com-airport:before { content: "\f2c3"; }
.i-com-apple-cable:before { content: "\f2c4"; }
.i-com-apple-tv:before { content: "\f2c5"; }
.i-com-book-code:before { content: "\f2c6"; }
.i-com-bug:before { content: "\f2c7"; }
.i-com-camera-2:before { content: "\f2c8"; }
.i-com-camera:before { content: "\f2c9"; }
.i-com-card:before { content: "\f2ca"; }
.i-com-cd:before { content: "\f2cb"; }
.i-com-chip:before { content: "\f2cc"; }
.i-com-circuit-2:before { content: "\f2cd"; }
.i-com-circuit:before { content: "\f2ce"; }
.i-com-code:before { content: "\f2cf"; }
.i-com-computer-10:before { content: "\f2d0"; }
.i-com-cross:before { content: "\f2d1"; }
.i-com-cursor:before { content: "\f2d2"; }
.i-com-disk:before { content: "\f2d3"; }
.i-com-download:before { content: "\f2d4"; }
.i-com-edit:before { content: "\f2d5"; }
.i-com-hard-drive-external:before { content: "\f2d6"; }
.i-com-joystick:before { content: "\f2d7"; }
.i-com-keyboard-attach:before { content: "\f2d8"; }
.i-com-keyboard-wireless:before { content: "\f2d9"; }
.i-com-keyboard:before { content: "\f2da"; }
.i-com-laptop-10:before { content: "\f2db"; }
.i-com-laptop-2:before { content: "\f2dc"; }
.i-com-laptop-code:before { content: "\f2dd"; }
.i-com-laptop-connected:before { content: "\f2de"; }
.i-com-laptop-web:before { content: "\f2df"; }
.i-com-laptop:before { content: "\f2e0"; }
.i-com-lightning-cable:before { content: "\f2e1"; }
.i-com-mac-old:before { content: "\f2e2"; }
.i-com-mac:before { content: "\f2e3"; }
.i-com-minus:before { content: "\f2e4"; }
.i-com-monitor:before { content: "\f2e5"; }
.i-com-mouse-mac:before { content: "\f2e6"; }
.i-com-mouse-wired:before { content: "\f2e7"; }
.i-com-mouse-wireless-mac:before { content: "\f2e8"; }
.i-com-mouse-wireless:before { content: "\f2e9"; }
.i-com-plug-uk:before { content: "\f2ea"; }
.i-com-plug:before { content: "\f2eb"; }
.i-com-plus:before { content: "\f2ec"; }
.i-com-power:before { content: "\f2ed"; }
.i-com-printer:before { content: "\f2ee"; }
.i-com-protect:before { content: "\f2ef"; }
.i-com-ram:before { content: "\f2f0"; }
.i-com-router:before { content: "\f2f1"; }
.i-com-scanner:before { content: "\f2f2"; }
.i-com-search:before { content: "\f2f3"; }
.i-com-settings:before { content: "\f2f4"; }
.i-com-shredder:before { content: "\f2f5"; }
.i-com-socket-network:before { content: "\f2f6"; }
.i-com-socket:before { content: "\f2f7"; }
.i-com-ssd:before { content: "\f2f8"; }
.i-com-tablet:before { content: "\f2f9"; }
.i-com-tick:before { content: "\f2fa"; }
.i-com-touchpad:before { content: "\f2fb"; }
.i-com-tower:before { content: "\f2fc"; }
.i-com-upload:before { content: "\f2fd"; }
.i-com-usb-bluetooth:before { content: "\f2fe"; }
.i-com-usb-cable:before { content: "\f2ff"; }
.i-com-usb-power:before { content: "\f300"; }
.i-com-usb-stick-2:before { content: "\f301"; }
.i-com-usb-stick:before { content: "\f302"; }
.i-com-usb:before { content: "\f303"; }
.i-com-warning:before { content: "\f304"; }
.i-com-window-10:before { content: "\f305"; }
.i-com-window-script:before { content: "\f306"; }
.i-con-angle-grinder:before { content: "\f307"; }
.i-con-axe:before { content: "\f308"; }
.i-con-barrier:before { content: "\f309"; }
.i-con-brush:before { content: "\f30a"; }
.i-con-brushes:before { content: "\f30b"; }
.i-con-chisels:before { content: "\f30c"; }
.i-con-circular-saw:before { content: "\f30d"; }
.i-con-club-hammer:before { content: "\f30e"; }
.i-con-cone:before { content: "\f30f"; }
.i-con-digger:before { content: "\f310"; }
.i-con-drill-bits:before { content: "\f311"; }
.i-con-drill:before { content: "\f312"; }
.i-con-electricity:before { content: "\f313"; }
.i-con-goggles-mask:before { content: "\f314"; }
.i-con-hammer-chisel:before { content: "\f315"; }
.i-con-hammer-screwdriver-2:before { content: "\f316"; }
.i-con-hammer-screwdriver:before { content: "\f317"; }
.i-con-hammer:before { content: "\f318"; }
.i-con-helmet-goggles:before { content: "\f319"; }
.i-con-helmet:before { content: "\f31a"; }
.i-con-jack-hammer:before { content: "\f31b"; }
.i-con-jacket:before { content: "\f31c"; }
.i-con-jigsaw:before { content: "\f31d"; }
.i-con-level:before { content: "\f31e"; }
.i-con-pliers:before { content: "\f31f"; }
.i-con-plumb-line:before { content: "\f320"; }
.i-con-protractor:before { content: "\f321"; }
.i-con-retractable-knife:before { content: "\f322"; }
.i-con-roller:before { content: "\f323"; }
.i-con-ruler-pencil:before { content: "\f324"; }
.i-con-sand-shovel:before { content: "\f325"; }
.i-con-saw:before { content: "\f326"; }
.i-con-screwdriver:before { content: "\f327"; }
.i-con-screwdrivers:before { content: "\f328"; }
.i-con-screws:before { content: "\f329"; }
.i-con-set-square:before { content: "\f32a"; }
.i-con-shovel:before { content: "\f32b"; }
.i-con-spade-fork:before { content: "\f32c"; }
.i-con-spanner-screwdriver:before { content: "\f32d"; }
.i-con-spanner:before { content: "\f32e"; }
.i-con-square:before { content: "\f32f"; }
.i-con-swiss-army-knife:before { content: "\f330"; }
.i-con-tape:before { content: "\f331"; }
.i-con-tin:before { content: "\f332"; }
.i-con-toolbox:before { content: "\f333"; }
.i-con-truck:before { content: "\f334"; }
.i-con-u-bend:before { content: "\f335"; }
.i-con-warning:before { content: "\f336"; }
.i-con-wheel-barrow:before { content: "\f337"; }
.i-con-workman:before { content: "\f338"; }
.i-db-column-width:before { content: "\f339"; }
.i-db-columns:before { content: "\f33a"; }
.i-db-copy:before { content: "\f33b"; }
.i-db-cross:before { content: "\f33c"; }
.i-db-database:before { content: "\f33d"; }
.i-db-databases:before { content: "\f33e"; }
.i-db-edit:before { content: "\f33f"; }
.i-db-location:before { content: "\f340"; }
.i-db-merge-columns:before { content: "\f341"; }
.i-db-merge-rows:before { content: "\f342"; }
.i-db-minus:before { content: "\f343"; }
.i-db-network-2:before { content: "\f344"; }
.i-db-network:before { content: "\f345"; }
.i-db-plus:before { content: "\f346"; }
.i-db-row-height:before { content: "\f347"; }
.i-db-rows:before { content: "\f348"; }
.i-db-search:before { content: "\f349"; }
.i-db-secure:before { content: "\f34a"; }
.i-db-settings:before { content: "\f34b"; }
.i-db-table-cells:before { content: "\f34c"; }
.i-db-table-cross:before { content: "\f34d"; }
.i-db-table-db:before { content: "\f34e"; }
.i-db-table-edit:before { content: "\f34f"; }
.i-db-table-list:before { content: "\f350"; }
.i-db-table-minus:before { content: "\f351"; }
.i-db-table-network-2:before { content: "\f352"; }
.i-db-table-network:before { content: "\f353"; }
.i-db-table-plus:before { content: "\f354"; }
.i-db-table-protect:before { content: "\f355"; }
.i-db-table-search:before { content: "\f356"; }
.i-db-table-settings:before { content: "\f357"; }
.i-db-table-stats:before { content: "\f358"; }
.i-db-table-tick:before { content: "\f359"; }
.i-db-table:before { content: "\f35a"; }
.i-db-tables-2:before { content: "\f35b"; }
.i-db-tables-connect-2:before { content: "\f35c"; }
.i-db-tables-connect:before { content: "\f35d"; }
.i-db-tables-swap:before { content: "\f35e"; }
.i-db-tables:before { content: "\f35f"; }
.i-db-tick:before { content: "\f360"; }
.i-db-warning:before { content: "\f361"; }
.i-des-airbrush-fat:before { content: "\f362"; }
.i-des-airbrush:before { content: "\f363"; }
.i-des-brush-block:before { content: "\f364"; }
.i-des-brush-fat:before { content: "\f365"; }
.i-des-brush-minus:before { content: "\f366"; }
.i-des-brush-plus:before { content: "\f367"; }
.i-des-brush-target:before { content: "\f368"; }
.i-des-brush-wide-fat:before { content: "\f369"; }
.i-des-brush-wide:before { content: "\f36a"; }
.i-des-brush:before { content: "\f36b"; }
.i-des-colour-palette:before { content: "\f36c"; }
.i-des-colour-wheel:before { content: "\f36d"; }
.i-des-craft-knife-fat:before { content: "\f36e"; }
.i-des-craft-knife:before { content: "\f36f"; }
.i-des-cut-line:before { content: "\f370"; }
.i-des-eraser-2:before { content: "\f371"; }
.i-des-eraser:before { content: "\f372"; }
.i-des-feather-pen:before { content: "\f373"; }
.i-des-glue-pot:before { content: "\f374"; }
.i-des-glue:before { content: "\f375"; }
.i-des-highlighter-fat:before { content: "\f376"; }
.i-des-highlighter:before { content: "\f377"; }
.i-des-ink-well:before { content: "\f378"; }
.i-des-inkpen-fat:before { content: "\f379"; }
.i-des-inkpen:before { content: "\f37a"; }
.i-des-magnet-block:before { content: "\f37b"; }
.i-des-magnet-minus:before { content: "\f37c"; }
.i-des-magnet-plus:before { content: "\f37d"; }
.i-des-magnet-target:before { content: "\f37e"; }
.i-des-magnet:before { content: "\f37f"; }
.i-des-magnify-fat:before { content: "\f380"; }
.i-des-magnify-minus:before { content: "\f381"; }
.i-des-magnify-plus:before { content: "\f382"; }
.i-des-magnify:before { content: "\f383"; }
.i-des-marker-fat:before { content: "\f384"; }
.i-des-marker:before { content: "\f385"; }
.i-des-origami:before { content: "\f386"; }
.i-des-paint:before { content: "\f387"; }
.i-des-paintbrush:before { content: "\f388"; }
.i-des-palette:before { content: "\f389"; }
.i-des-pen-pot:before { content: "\f38a"; }
.i-des-pencil-block:before { content: "\f38b"; }
.i-des-pencil-fat:before { content: "\f38c"; }
.i-des-pencil-minus:before { content: "\f38d"; }
.i-des-pencil-plus:before { content: "\f38e"; }
.i-des-pencil-target:before { content: "\f38f"; }
.i-des-pencil:before { content: "\f390"; }
.i-des-pipette-block:before { content: "\f391"; }
.i-des-pipette-fat:before { content: "\f392"; }
.i-des-pipette-target:before { content: "\f393"; }
.i-des-pipette:before { content: "\f394"; }
.i-des-pour-block:before { content: "\f395"; }
.i-des-pour-minus:before { content: "\f396"; }
.i-des-pour-plus:before { content: "\f397"; }
.i-des-pour-target:before { content: "\f398"; }
.i-des-pour:before { content: "\f399"; }
.i-des-protractor:before { content: "\f39a"; }
.i-des-ruler-fat:before { content: "\f39b"; }
.i-des-ruler:before { content: "\f39c"; }
.i-des-scalpel-block:before { content: "\f39d"; }
.i-des-scalpel-fat:before { content: "\f39e"; }
.i-des-scalpel-minus:before { content: "\f39f"; }
.i-des-scalpel-plus:before { content: "\f3a0"; }
.i-des-scalpel-target:before { content: "\f3a1"; }
.i-des-scalpel:before { content: "\f3a2"; }
.i-des-scissors-cut:before { content: "\f3a3"; }
.i-des-scissors:before { content: "\f3a4"; }
.i-des-sharpener:before { content: "\f3a5"; }
.i-des-stamp:before { content: "\f3a6"; }
.i-des-swatches:before { content: "\f3a7"; }
.i-des-technical-pen-fat:before { content: "\f3a8"; }
.i-des-technical-pen:before { content: "\f3a9"; }
.i-des-technical-pencil-fat:before { content: "\f3aa"; }
.i-des-technical-pencil:before { content: "\f3ab"; }
.i-des-wand-2:before { content: "\f3ac"; }
.i-des-wand-block:before { content: "\f3ad"; }
.i-des-wand-minus:before { content: "\f3ae"; }
.i-des-wand-plus:before { content: "\f3af"; }
.i-des-wand-target:before { content: "\f3b0"; }
.i-des-wand:before { content: "\f3b1"; }
.i-doc-book:before { content: "\f3b2"; }
.i-doc-box:before { content: "\f3b3"; }
.i-doc-certificate:before { content: "\f3b4"; }
.i-doc-clipboard:before { content: "\f3b5"; }
.i-doc-contract:before { content: "\f3b6"; }
.i-doc-copy:before { content: "\f3b7"; }
.i-doc-cut-2:before { content: "\f3b8"; }
.i-doc-cut:before { content: "\f3b9"; }
.i-doc-document:before { content: "\f3ba"; }
.i-doc-documents:before { content: "\f3bb"; }
.i-doc-file:before { content: "\f3bc"; }
.i-doc-filing-2:before { content: "\f3bd"; }
.i-doc-filing-3:before { content: "\f3be"; }
.i-doc-filing:before { content: "\f3bf"; }
.i-doc-layers-2:before { content: "\f3c0"; }
.i-doc-layers:before { content: "\f3c1"; }
.i-doc-ledger:before { content: "\f3c2"; }
.i-doc-lever-arch:before { content: "\f3c3"; }
.i-doc-newspaper:before { content: "\f3c4"; }
.i-doc-note:before { content: "\f3c5"; }
.i-doc-notebook:before { content: "\f3c6"; }
.i-doc-notepad:before { content: "\f3c7"; }
.i-doc-paste:before { content: "\f3c8"; }
.i-doc-pen-sign:before { content: "\f3c9"; }
.i-doc-pile:before { content: "\f3ca"; }
.i-doc-resume-2:before { content: "\f3cb"; }
.i-doc-resume:before { content: "\f3cc"; }
.i-doc-scroll:before { content: "\f3cd"; }
.i-doc-signature:before { content: "\f3ce"; }
.i-doc-tasks:before { content: "\f3cf"; }
.i-dri-aperitif:before { content: "\f3d0"; }
.i-dri-beer-bottle:before { content: "\f3d1"; }
.i-dri-beer-champagne:before { content: "\f3d2"; }
.i-dri-beer-chaser:before { content: "\f3d3"; }
.i-dri-beer:before { content: "\f3d4"; }
.i-dri-bottle-champagne:before { content: "\f3d5"; }
.i-dri-bottle-opener:before { content: "\f3d6"; }
.i-dri-bottle-wine:before { content: "\f3d7"; }
.i-dri-cafetiere:before { content: "\f3d8"; }
.i-dri-can:before { content: "\f3d9"; }
.i-dri-champagne:before { content: "\f3da"; }
.i-dri-cocktail-2:before { content: "\f3db"; }
.i-dri-cocktail-3:before { content: "\f3dc"; }
.i-dri-cocktail-4:before { content: "\f3dd"; }
.i-dri-cocktail:before { content: "\f3de"; }
.i-dri-coffee-bean:before { content: "\f3df"; }
.i-dri-coffee-jug:before { content: "\f3e0"; }
.i-dri-cork:before { content: "\f3e1"; }
.i-dri-corkscrew:before { content: "\f3e2"; }
.i-dri-cubes:before { content: "\f3e3"; }
.i-dri-cup-coffee:before { content: "\f3e4"; }
.i-dri-cup-tea-2:before { content: "\f3e5"; }
.i-dri-cup-tea:before { content: "\f3e6"; }
.i-dri-fizzy-bottle:before { content: "\f3e7"; }
.i-dri-fizzy-glass:before { content: "\f3e8"; }
.i-dri-glass-ice:before { content: "\f3e9"; }
.i-dri-jug-glass:before { content: "\f3ea"; }
.i-dri-jug:before { content: "\f3eb"; }
.i-dri-juice-bottle:before { content: "\f3ec"; }
.i-dri-kettle-old:before { content: "\f3ed"; }
.i-dri-milk-bottle:before { content: "\f3ee"; }
.i-dri-milk-jug:before { content: "\f3ef"; }
.i-dri-milkshake:before { content: "\f3f0"; }
.i-dri-straw-lemon:before { content: "\f3f1"; }
.i-dri-takeaway-coffee:before { content: "\f3f2"; }
.i-dri-takeaway:before { content: "\f3f3"; }
.i-dri-tankard:before { content: "\f3f4"; }
.i-dri-teapot:before { content: "\f3f5"; }
.i-dri-wine-glass-2:before { content: "\f3f6"; }
.i-dri-wine-glass:before { content: "\f3f7"; }
.i-ema-attach:before { content: "\f3f8"; }
.i-ema-calendar:before { content: "\f3f9"; }
.i-ema-contacts:before { content: "\f3fa"; }
.i-ema-cross:before { content: "\f3fb"; }
.i-ema-download:before { content: "\f3fc"; }
.i-ema-edit:before { content: "\f3fd"; }
.i-ema-email:before { content: "\f3fe"; }
.i-ema-emails-2:before { content: "\f3ff"; }
.i-ema-emails:before { content: "\f400"; }
.i-ema-flag:before { content: "\f401"; }
.i-ema-heart:before { content: "\f402"; }
.i-ema-minus:before { content: "\f403"; }
.i-ema-open-doc:before { content: "\f404"; }
.i-ema-open-picture:before { content: "\f405"; }
.i-ema-open:before { content: "\f406"; }
.i-ema-picture:before { content: "\f407"; }
.i-ema-plane:before { content: "\f408"; }
.i-ema-plus:before { content: "\f409"; }
.i-ema-post-mark:before { content: "\f40a"; }
.i-ema-postage-stamp:before { content: "\f40b"; }
.i-ema-postcard:before { content: "\f40c"; }
.i-ema-receive:before { content: "\f40d"; }
.i-ema-reply-all:before { content: "\f40e"; }
.i-ema-reply:before { content: "\f40f"; }
.i-ema-seal:before { content: "\f410"; }
.i-ema-search:before { content: "\f411"; }
.i-ema-send-2:before { content: "\f412"; }
.i-ema-send-receive:before { content: "\f413"; }
.i-ema-send:before { content: "\f414"; }
.i-ema-settings:before { content: "\f415"; }
.i-ema-stamp:before { content: "\f416"; }
.i-ema-trash:before { content: "\f417"; }
.i-ema-upload:before { content: "\f418"; }
.i-ema-user:before { content: "\f419"; }
.i-ema-warning:before { content: "\f41a"; }
.i-email-tick:before { content: "\f41b"; }
.i-emo-alien:before { content: "\f41c"; }
.i-emo-angry:before { content: "\f41d"; }
.i-emo-baby:before { content: "\f41e"; }
.i-emo-big-eyes:before { content: "\f41f"; }
.i-emo-calm:before { content: "\f420"; }
.i-emo-cap:before { content: "\f421"; }
.i-emo-cheeky:before { content: "\f422"; }
.i-emo-cheery:before { content: "\f423"; }
.i-emo-chortle:before { content: "\f424"; }
.i-emo-clench:before { content: "\f425"; }
.i-emo-clever:before { content: "\f426"; }
.i-emo-clown:before { content: "\f427"; }
.i-emo-confused:before { content: "\f428"; }
.i-emo-cool:before { content: "\f429"; }
.i-emo-crying:before { content: "\f42a"; }
.i-emo-cyclops:before { content: "\f42b"; }
.i-emo-dazed:before { content: "\f42c"; }
.i-emo-dizzy:before { content: "\f42d"; }
.i-emo-doctor:before { content: "\f42e"; }
.i-emo-dracula:before { content: "\f42f"; }
.i-emo-evil:before { content: "\f430"; }
.i-emo-famous:before { content: "\f431"; }
.i-emo-freckly:before { content: "\f432"; }
.i-emo-frightened:before { content: "\f433"; }
.i-emo-glasses:before { content: "\f434"; }
.i-emo-goofy:before { content: "\f435"; }
.i-emo-happy:before { content: "\f436"; }
.i-emo-ill:before { content: "\f437"; }
.i-emo-innocent:before { content: "\f438"; }
.i-emo-laugh:before { content: "\f439"; }
.i-emo-love:before { content: "\f43a"; }
.i-emo-mad:before { content: "\f43b"; }
.i-emo-memory:before { content: "\f43c"; }
.i-emo-money:before { content: "\f43d"; }
.i-emo-ninja:before { content: "\f43e"; }
.i-emo-nurse:before { content: "\f43f"; }
.i-emo-old:before { content: "\f440"; }
.i-emo-pain:before { content: "\f441"; }
.i-emo-pirate:before { content: "\f442"; }
.i-emo-posh:before { content: "\f443"; }
.i-emo-robot:before { content: "\f444"; }
.i-emo-sad:before { content: "\f445"; }
.i-emo-scarecrow:before { content: "\f446"; }
.i-emo-scary:before { content: "\f447"; }
.i-emo-scream:before { content: "\f448"; }
.i-emo-sexy:before { content: "\f449"; }
.i-emo-shy:before { content: "\f44a"; }
.i-emo-sick:before { content: "\f44b"; }
.i-emo-silenced:before { content: "\f44c"; }
.i-emo-sleep:before { content: "\f44d"; }
.i-emo-smiley:before { content: "\f44e"; }
.i-emo-smug:before { content: "\f44f"; }
.i-emo-square-eyes:before { content: "\f450"; }
.i-emo-strain:before { content: "\f451"; }
.i-emo-stunned:before { content: "\f452"; }
.i-emo-thoughtful:before { content: "\f453"; }
.i-emo-vampire:before { content: "\f454"; }
.i-emo-whistle:before { content: "\f455"; }
.i-emo-wink:before { content: "\f456"; }
.i-emo-worried:before { content: "\f457"; }
.i-ext-aac:before { content: "\f458"; }
.i-ext-ai:before { content: "\f459"; }
.i-ext-aiff:before { content: "\f45a"; }
.i-ext-avi:before { content: "\f45b"; }
.i-ext-bmp:before { content: "\f45c"; }
.i-ext-css:before { content: "\f45d"; }
.i-ext-csv:before { content: "\f45e"; }
.i-ext-db:before { content: "\f45f"; }
.i-ext-dll:before { content: "\f460"; }
.i-ext-doc:before { content: "\f461"; }
.i-ext-eot:before { content: "\f462"; }
.i-ext-eps:before { content: "\f463"; }
.i-ext-exe:before { content: "\f464"; }
.i-ext-flv:before { content: "\f465"; }
.i-ext-gif:before { content: "\f466"; }
.i-ext-html:before { content: "\f467"; }
.i-ext-jpg:before { content: "\f468"; }
.i-ext-key:before { content: "\f469"; }
.i-ext-mov:before { content: "\f46a"; }
.i-ext-mp3:before { content: "\f46b"; }
.i-ext-mp4:before { content: "\f46c"; }
.i-ext-mpg:before { content: "\f46d"; }
.i-ext-otf:before { content: "\f46e"; }
.i-ext-page:before { content: "\f46f"; }
.i-ext-pct:before { content: "\f470"; }
.i-ext-pdf:before { content: "\f471"; }
.i-ext-png:before { content: "\f472"; }
.i-ext-ppt:before { content: "\f473"; }
.i-ext-ps:before { content: "\f474"; }
.i-ext-psd:before { content: "\f475"; }
.i-ext-rar:before { content: "\f476"; }
.i-ext-raw:before { content: "\f477"; }
.i-ext-sql:before { content: "\f478"; }
.i-ext-svg:before { content: "\f479"; }
.i-ext-tar:before { content: "\f47a"; }
.i-ext-tif:before { content: "\f47b"; }
.i-ext-ttf:before { content: "\f47c"; }
.i-ext-txt:before { content: "\f47d"; }
.i-ext-wav:before { content: "\f47e"; }
.i-ext-wma:before { content: "\f47f"; }
.i-ext-wmv:before { content: "\f480"; }
.i-ext-woff:before { content: "\f481"; }
.i-ext-xls:before { content: "\f482"; }
.i-ext-xml:before { content: "\f483"; }
.i-ext-zip:before { content: "\f484"; }
.i-fil-alarm:before { content: "\f485"; }
.i-fil-attach:before { content: "\f486"; }
.i-fil-audio:before { content: "\f487"; }
.i-fil-calendar:before { content: "\f488"; }
.i-fil-chat:before { content: "\f489"; }
.i-fil-code:before { content: "\f48a"; }
.i-fil-cross:before { content: "\f48b"; }
.i-fil-data:before { content: "\f48c"; }
.i-fil-database:before { content: "\f48d"; }
.i-fil-divider:before { content: "\f48e"; }
.i-fil-download:before { content: "\f48f"; }
.i-fil-edit:before { content: "\f490"; }
.i-fil-email:before { content: "\f491"; }
.i-fil-flag:before { content: "\f492"; }
.i-fil-graph:before { content: "\f493"; }
.i-fil-image:before { content: "\f494"; }
.i-fil-information:before { content: "\f495"; }
.i-fil-location:before { content: "\f496"; }
.i-fil-locked:before { content: "\f497"; }
.i-fil-love:before { content: "\f498"; }
.i-fil-minus:before { content: "\f499"; }
.i-fil-movie:before { content: "\f49a"; }
.i-fil-music:before { content: "\f49b"; }
.i-fil-play:before { content: "\f49c"; }
.i-fil-plus:before { content: "\f49d"; }
.i-fil-protect:before { content: "\f49e"; }
.i-fil-question:before { content: "\f49f"; }
.i-fil-receive:before { content: "\f4a0"; }
.i-fil-search:before { content: "\f4a1"; }
.i-fil-send:before { content: "\f4a2"; }
.i-fil-settings:before { content: "\f4a3"; }
.i-fil-star:before { content: "\f4a4"; }
.i-fil-statistics:before { content: "\f4a5"; }
.i-fil-text:before { content: "\f4a6"; }
.i-fil-tick:before { content: "\f4a7"; }
.i-fil-time:before { content: "\f4a8"; }
.i-fil-trash:before { content: "\f4a9"; }
.i-fil-upload:before { content: "\f4aa"; }
.i-fil-user:before { content: "\f4ab"; }
.i-fil-warning:before { content: "\f4ac"; }
.i-fin-bank:before { content: "\f4ad"; }
.i-fin-bitcoin:before { content: "\f4ae"; }
.i-fin-calculator:before { content: "\f4af"; }
.i-fin-card-back:before { content: "\f4b0"; }
.i-fin-card-front-back:before { content: "\f4b1"; }
.i-fin-card-machine:before { content: "\f4b2"; }
.i-fin-coin-dollar:before { content: "\f4b3"; }
.i-fin-coin-euro:before { content: "\f4b4"; }
.i-fin-coin-rupee:before { content: "\f4b5"; }
.i-fin-coin-sterling:before { content: "\f4b6"; }
.i-fin-coin-yen-yuan:before { content: "\f4b7"; }
.i-fin-coins:before { content: "\f4b8"; }
.i-fin-conversion:before { content: "\f4b9"; }
.i-fin-credit-card:before { content: "\f4ba"; }
.i-fin-dollar-bag:before { content: "\f4bb"; }
.i-fin-dollar-computer:before { content: "\f4bc"; }
.i-fin-dollar-mobile:before { content: "\f4bd"; }
.i-fin-dollar:before { content: "\f4be"; }
.i-fin-euro-bag:before { content: "\f4bf"; }
.i-fin-euro:before { content: "\f4c0"; }
.i-fin-gold-bars-2:before { content: "\f4c1"; }
.i-fin-gold-bars:before { content: "\f4c2"; }
.i-fin-note-card:before { content: "\f4c3"; }
.i-fin-note-dollar:before { content: "\f4c4"; }
.i-fin-note-euro:before { content: "\f4c5"; }
.i-fin-note-rupee:before { content: "\f4c6"; }
.i-fin-note-sterling:before { content: "\f4c7"; }
.i-fin-note-yen-yuan:before { content: "\f4c8"; }
.i-fin-notes-coins:before { content: "\f4c9"; }
.i-fin-notes-machine:before { content: "\f4ca"; }
.i-fin-notes:before { content: "\f4cb"; }
.i-fin-piggy-bank-coin:before { content: "\f4cc"; }
.i-fin-piggy-bank:before { content: "\f4cd"; }
.i-fin-rupee-bag:before { content: "\f4ce"; }
.i-fin-rupee:before { content: "\f4cf"; }
.i-fin-sterling-bag:before { content: "\f4d0"; }
.i-fin-sterling:before { content: "\f4d1"; }
.i-fin-subscription:before { content: "\f4d2"; }
.i-fin-yen-yuan-bag:before { content: "\f4d3"; }
.i-fin-yen:before { content: "\f4d4"; }
.i-fit-9-pin-bowling:before { content: "\f4d5"; }
.i-fit-archery:before { content: "\f4d6"; }
.i-fit-baseball-player:before { content: "\f4d7"; }
.i-fit-basketball-player:before { content: "\f4d8"; }
.i-fit-cross-country-skiing:before { content: "\f4d9"; }
.i-fit-cycling:before { content: "\f4da"; }
.i-fit-exercise:before { content: "\f4db"; }
.i-fit-fencing:before { content: "\f4dc"; }
.i-fit-golf-putt:before { content: "\f4dd"; }
.i-fit-golf-swing:before { content: "\f4de"; }
.i-fit-gymnastics:before { content: "\f4df"; }
.i-fit-hockey:before { content: "\f4e0"; }
.i-fit-hurdles:before { content: "\f4e1"; }
.i-fit-ice-hockey:before { content: "\f4e2"; }
.i-fit-ice-skating:before { content: "\f4e3"; }
.i-fit-jogging:before { content: "\f4e4"; }
.i-fit-karate:before { content: "\f4e5"; }
.i-fit-kickboxing:before { content: "\f4e6"; }
.i-fit-man:before { content: "\f4e7"; }
.i-fit-pilates:before { content: "\f4e8"; }
.i-fit-roller-blading:before { content: "\f4e9"; }
.i-fit-rugby-player:before { content: "\f4ea"; }
.i-fit-running:before { content: "\f4eb"; }
.i-fit-skateboarding:before { content: "\f4ec"; }
.i-fit-skiing:before { content: "\f4ed"; }
.i-fit-skipping:before { content: "\f4ee"; }
.i-fit-soccer-player:before { content: "\f4ef"; }
.i-fit-sprint-cycling:before { content: "\f4f0"; }
.i-fit-sprinting:before { content: "\f4f1"; }
.i-fit-star-jumps:before { content: "\f4f2"; }
.i-fit-stretching:before { content: "\f4f3"; }
.i-fit-surfer:before { content: "\f4f4"; }
.i-fit-swimming:before { content: "\f4f5"; }
.i-fit-tennis:before { content: "\f4f6"; }
.i-fit-volleyball:before { content: "\f4f7"; }
.i-fit-walking:before { content: "\f4f8"; }
.i-fit-water-volleyball:before { content: "\f4f9"; }
.i-fit-weights:before { content: "\f4fa"; }
.i-fit-woman:before { content: "\f4fb"; }
.i-fit-yoga:before { content: "\f4fc"; }
.i-fol-alarm:before { content: "\f4fd"; }
.i-fol-attach:before { content: "\f4fe"; }
.i-fol-audio:before { content: "\f4ff"; }
.i-fol-calendar:before { content: "\f500"; }
.i-fol-chat:before { content: "\f501"; }
.i-fol-code:before { content: "\f502"; }
.i-fol-cross:before { content: "\f503"; }
.i-fol-data:before { content: "\f504"; }
.i-fol-database:before { content: "\f505"; }
.i-fol-divider:before { content: "\f506"; }
.i-fol-download:before { content: "\f507"; }
.i-fol-edit:before { content: "\f508"; }
.i-fol-email:before { content: "\f509"; }
.i-fol-flag:before { content: "\f50a"; }
.i-fol-folder-2:before { content: "\f50b"; }
.i-fol-folder:before { content: "\f50c"; }
.i-fol-graph:before { content: "\f50d"; }
.i-fol-image:before { content: "\f50e"; }
.i-fol-information:before { content: "\f50f"; }
.i-fol-location:before { content: "\f510"; }
.i-fol-locked:before { content: "\f511"; }
.i-fol-love:before { content: "\f512"; }
.i-fol-minus:before { content: "\f513"; }
.i-fol-movie:before { content: "\f514"; }
.i-fol-music:before { content: "\f515"; }
.i-fol-play:before { content: "\f516"; }
.i-fol-plus:before { content: "\f517"; }
.i-fol-protect:before { content: "\f518"; }
.i-fol-question:before { content: "\f519"; }
.i-fol-receive:before { content: "\f51a"; }
.i-fol-search:before { content: "\f51b"; }
.i-fol-send:before { content: "\f51c"; }
.i-fol-settings:before { content: "\f51d"; }
.i-fol-star:before { content: "\f51e"; }
.i-fol-statistics:before { content: "\f51f"; }
.i-fol-text:before { content: "\f520"; }
.i-fol-tick:before { content: "\f521"; }
.i-fol-time:before { content: "\f522"; }
.i-fol-trash:before { content: "\f523"; }
.i-fol-upload:before { content: "\f524"; }
.i-fol-user:before { content: "\f525"; }
.i-fol-warning:before { content: "\f526"; }
.i-foo-baked-beans:before { content: "\f527"; }
.i-foo-bread:before { content: "\f528"; }
.i-foo-cake:before { content: "\f529"; }
.i-foo-carton:before { content: "\f52a"; }
.i-foo-cheese-2:before { content: "\f52b"; }
.i-foo-cheese:before { content: "\f52c"; }
.i-foo-cheeseburger:before { content: "\f52d"; }
.i-foo-chupa:before { content: "\f52e"; }
.i-foo-coissant:before { content: "\f52f"; }
.i-foo-cracked-egg:before { content: "\f530"; }
.i-foo-cupcake-2:before { content: "\f531"; }
.i-foo-cupcake:before { content: "\f532"; }
.i-foo-doughnut-2:before { content: "\f533"; }
.i-foo-drumstick:before { content: "\f534"; }
.i-foo-egg-sausage:before { content: "\f535"; }
.i-foo-egg-spoon:before { content: "\f536"; }
.i-foo-egg:before { content: "\f537"; }
.i-foo-fast-food:before { content: "\f538"; }
.i-foo-fish-bones:before { content: "\f539"; }
.i-foo-fish:before { content: "\f53a"; }
.i-foo-fried-egg:before { content: "\f53b"; }
.i-foo-fries:before { content: "\f53c"; }
.i-foo-ham:before { content: "\f53d"; }
.i-foo-hamburger:before { content: "\f53e"; }
.i-foo-hot-dog:before { content: "\f53f"; }
.i-foo-ice-cream-2:before { content: "\f540"; }
.i-foo-ice-cream:before { content: "\f541"; }
.i-foo-ice-lolly-2:before { content: "\f542"; }
.i-foo-ice-lolly-3:before { content: "\f543"; }
.i-foo-ice-lolly:before { content: "\f544"; }
.i-foo-iced-bun:before { content: "\f545"; }
.i-foo-iced-doughnut:before { content: "\f546"; }
.i-foo-jam:before { content: "\f547"; }
.i-foo-jar:before { content: "\f548"; }
.i-foo-kebab:before { content: "\f549"; }
.i-foo-lollipop:before { content: "\f54a"; }
.i-foo-muffin-2:before { content: "\f54b"; }
.i-foo-muffin:before { content: "\f54c"; }
.i-foo-noodle-box-2:before { content: "\f54d"; }
.i-foo-noodle-box:before { content: "\f54e"; }
.i-foo-noodles:before { content: "\f54f"; }
.i-foo-pie:before { content: "\f550"; }
.i-foo-pizza-slice:before { content: "\f551"; }
.i-foo-pizza:before { content: "\f552"; }
.i-foo-popcorn:before { content: "\f553"; }
.i-foo-roast-chicken:before { content: "\f554"; }
.i-foo-runny-egg:before { content: "\f555"; }
.i-foo-salami:before { content: "\f556"; }
.i-foo-sauces:before { content: "\f557"; }
.i-foo-sausage:before { content: "\f558"; }
.i-foo-soup:before { content: "\f559"; }
.i-foo-steak:before { content: "\f55a"; }
.i-foo-sushi:before { content: "\f55b"; }
.i-foo-tin-small:before { content: "\f55c"; }
.i-foo-tin:before { content: "\f55d"; }
.i-foo-toast-jam:before { content: "\f55e"; }
.i-foo-toast:before { content: "\f55f"; }
.i-foo-tub:before { content: "\f560"; }
.i-veg-apple-bite:before { content: "\f561"; }
.i-veg-apple-core:before { content: "\f562"; }
.i-veg-apple-cut:before { content: "\f563"; }
.i-veg-apple:before { content: "\f564"; }
.i-veg-avocado-cut:before { content: "\f565"; }
.i-veg-banana-peeled:before { content: "\f566"; }
.i-veg-banana:before { content: "\f567"; }
.i-veg-brocolli:before { content: "\f568"; }
.i-veg-cabbage:before { content: "\f569"; }
.i-veg-carrot:before { content: "\f56a"; }
.i-veg-cauliflower:before { content: "\f56b"; }
.i-veg-cherry:before { content: "\f56c"; }
.i-veg-chilli:before { content: "\f56d"; }
.i-veg-corn:before { content: "\f56e"; }
.i-veg-eggplant:before { content: "\f56f"; }
.i-veg-grapes:before { content: "\f570"; }
.i-veg-kidney-bean:before { content: "\f571"; }
.i-veg-lemon:before { content: "\f572"; }
.i-veg-mushroom-cut:before { content: "\f573"; }
.i-veg-mushroom:before { content: "\f574"; }
.i-veg-onion-cut:before { content: "\f575"; }
.i-veg-orange-cut:before { content: "\f576"; }
.i-veg-orange:before { content: "\f577"; }
.i-veg-peach:before { content: "\f578"; }
.i-veg-peapod:before { content: "\f579"; }
.i-veg-pear-bite:before { content: "\f57a"; }
.i-veg-pear-cut:before { content: "\f57b"; }
.i-veg-pear:before { content: "\f57c"; }
.i-veg-pepper:before { content: "\f57d"; }
.i-veg-pineapple:before { content: "\f57e"; }
.i-veg-plum:before { content: "\f57f"; }
.i-veg-potato:before { content: "\f580"; }
.i-veg-pumpkin:before { content: "\f581"; }
.i-veg-rasberry:before { content: "\f582"; }
.i-veg-strawberry:before { content: "\f583"; }
.i-veg-tomato:before { content: "\f584"; }
.i-veg-turnip:before { content: "\f585"; }
.i-veg-watermellon-cut:before { content: "\f586"; }
.i-veg-watermellon-slice:before { content: "\f587"; }
.i-gen-alarm-off:before { content: "\f588"; }
.i-gen-alarm:before { content: "\f589"; }
.i-gen-baby-cutlery:before { content: "\f58a"; }
.i-gen-bookmark:before { content: "\f58b"; }
.i-gen-cog:before { content: "\f58c"; }
.i-gen-cogs:before { content: "\f58d"; }
.i-gen-controller-ps:before { content: "\f58e"; }
.i-gen-controller-xbox:before { content: "\f58f"; }
.i-gen-crowd:before { content: "\f590"; }
.i-gen-cut:before { content: "\f591"; }
.i-gen-cutting:before { content: "\f592"; }
.i-gen-extinguisher:before { content: "\f593"; }
.i-gen-female:before { content: "\f594"; }
.i-gen-filter:before { content: "\f595"; }
.i-gen-flag-2:before { content: "\f596"; }
.i-gen-flag-3:before { content: "\f597"; }
.i-gen-flag:before { content: "\f598"; }
.i-gen-flow:before { content: "\f599"; }
.i-gen-gameboy-advance:before { content: "\f59a"; }
.i-gen-gameboy:before { content: "\f59b"; }
.i-gen-games-ios:before { content: "\f59c"; }
.i-gen-gecko:before { content: "\f59d"; }
.i-gen-heart-rate-2:before { content: "\f59e"; }
.i-gen-heart-rate:before { content: "\f59f"; }
.i-gen-hierarchy:before { content: "\f5a0"; }
.i-gen-home:before { content: "\f5a1"; }
.i-gen-igloo:before { content: "\f5a2"; }
.i-gen-infinity:before { content: "\f5a3"; }
.i-gen-information:before { content: "\f5a4"; }
.i-gen-jewel:before { content: "\f5a5"; }
.i-gen-jigsaw:before { content: "\f5a6"; }
.i-gen-joystick:before { content: "\f5a7"; }
.i-gen-land:before { content: "\f5a8"; }
.i-gen-lifebelt:before { content: "\f5a9"; }
.i-gen-light-bulb-on:before { content: "\f5aa"; }
.i-gen-light-bulb:before { content: "\f5ab"; }
.i-gen-light:before { content: "\f5ac"; }
.i-gen-male-female-2:before { content: "\f5ad"; }
.i-gen-male-female:before { content: "\f5ae"; }
.i-gen-male:before { content: "\f5af"; }
.i-gen-medical-kit:before { content: "\f5b0"; }
.i-gen-mind-map:before { content: "\f5b1"; }
.i-gen-mortar-board:before { content: "\f5b2"; }
.i-gen-paperclip-2:before { content: "\f5b3"; }
.i-gen-paperclip:before { content: "\f5b4"; }
.i-gen-paw-print:before { content: "\f5b5"; }
.i-gen-pill:before { content: "\f5b6"; }
.i-gen-reservation:before { content: "\f5b7"; }
.i-gen-rss:before { content: "\f5b8"; }
.i-gen-sack:before { content: "\f5b9"; }
.i-gen-sights:before { content: "\f5ba"; }
.i-gen-stroller:before { content: "\f5bb"; }
.i-gen-take-off:before { content: "\f5bc"; }
.i-gen-target:before { content: "\f5bd"; }
.i-gen-warning:before { content: "\f5be"; }
.i-hal-arrow-head:before { content: "\f5bf"; }
.i-hal-axe:before { content: "\f5c0"; }
.i-hal-bat:before { content: "\f5c1"; }
.i-hal-bones:before { content: "\f5c2"; }
.i-hal-broom-hat:before { content: "\f5c3"; }
.i-hal-cauldron:before { content: "\f5c4"; }
.i-hal-date-31:before { content: "\f5c5"; }
.i-hal-fangs:before { content: "\f5c6"; }
.i-hal-frankenstein:before { content: "\f5c7"; }
.i-hal-ghost:before { content: "\f5c8"; }
.i-hal-grave:before { content: "\f5c9"; }
.i-hal-head-cleaver:before { content: "\f5ca"; }
.i-hal-lightening:before { content: "\f5cb"; }
.i-hal-mask:before { content: "\f5cc"; }
.i-hal-masquerade:before { content: "\f5cd"; }
.i-hal-mummy:before { content: "\f5ce"; }
.i-hal-pumpkin:before { content: "\f5cf"; }
.i-hal-rip:before { content: "\f5d0"; }
.i-hal-scythe:before { content: "\f5d1"; }
.i-hal-skull:before { content: "\f5d2"; }
.i-hal-spider:before { content: "\f5d3"; }
.i-hal-tree:before { content: "\f5d4"; }
.i-hal-wand:before { content: "\f5d5"; }
.i-hal-web-2:before { content: "\f5d6"; }
.i-hal-web:before { content: "\f5d7"; }
.i-hal-witch-hat:before { content: "\f5d8"; }
.i-hou-armchair:before { content: "\f5d9"; }
.i-hou-bath-shower:before { content: "\f5da"; }
.i-hou-bathroom-cabinet:before { content: "\f5db"; }
.i-hou-bed-bunk:before { content: "\f5dc"; }
.i-hou-bed-double:before { content: "\f5dd"; }
.i-hou-bed-single:before { content: "\f5de"; }
.i-hou-bedside-old:before { content: "\f5df"; }
.i-hou-bedside:before { content: "\f5e0"; }
.i-hou-bookcase:before { content: "\f5e1"; }
.i-hou-bookshelf:before { content: "\f5e2"; }
.i-hou-cabinet-old:before { content: "\f5e3"; }
.i-hou-cabinet:before { content: "\f5e4"; }
.i-hou-candelabrar:before { content: "\f5e5"; }
.i-hou-chair-bar:before { content: "\f5e6"; }
.i-hou-chair-dining:before { content: "\f5e7"; }
.i-hou-chair-office:before { content: "\f5e8"; }
.i-hou-chair-old:before { content: "\f5e9"; }
.i-hou-chair:before { content: "\f5ea"; }
.i-hou-chandelier:before { content: "\f5eb"; }
.i-hou-coat-stand:before { content: "\f5ec"; }
.i-hou-door:before { content: "\f5ed"; }
.i-hou-drawers-old:before { content: "\f5ee"; }
.i-hou-drawers:before { content: "\f5ef"; }
.i-hou-fire:before { content: "\f5f0"; }
.i-hou-frame:before { content: "\f5f1"; }
.i-hou-futon:before { content: "\f5f2"; }
.i-hou-handle-lock:before { content: "\f5f3"; }
.i-hou-handle:before { content: "\f5f4"; }
.i-hou-light-ceiling:before { content: "\f5f5"; }
.i-hou-light-desk:before { content: "\f5f6"; }
.i-hou-light-pendant:before { content: "\f5f7"; }
.i-hou-light-stand:before { content: "\f5f8"; }
.i-hou-light-table:before { content: "\f5f9"; }
.i-hou-light-wall:before { content: "\f5fa"; }
.i-hou-mirror-stand:before { content: "\f5fb"; }
.i-hou-mirror-table:before { content: "\f5fc"; }
.i-hou-mirror:before { content: "\f5fd"; }
.i-hou-picture-landscape:before { content: "\f5fe"; }
.i-hou-picture-portrait:before { content: "\f5ff"; }
.i-hou-picture:before { content: "\f600"; }
.i-hou-shower-2:before { content: "\f601"; }
.i-hou-shower:before { content: "\f602"; }
.i-hou-sink:before { content: "\f603"; }
.i-hou-sofa:before { content: "\f604"; }
.i-hou-stool:before { content: "\f605"; }
.i-hou-table-office:before { content: "\f606"; }
.i-hou-table:before { content: "\f607"; }
.i-hou-tap:before { content: "\f608"; }
.i-hou-toilet:before { content: "\f609"; }
.i-hou-tv-cabinet:before { content: "\f60a"; }
.i-hou-wardrobe-old:before { content: "\f60b"; }
.i-hou-wardrobe:before { content: "\f60c"; }
.i-hou-window-blind:before { content: "\f60d"; }
.i-hou-window-blinds:before { content: "\f60e"; }
.i-hou-window-curtains:before { content: "\f60f"; }
.i-hou-window-curved:before { content: "\f610"; }
.i-hou-window-open:before { content: "\f611"; }
.i-hou-window-roman:before { content: "\f612"; }
.i-hou-window-round:before { content: "\f613"; }
.i-hou-window:before { content: "\f614"; }
.i-img-album:before { content: "\f615"; }
.i-img-attach:before { content: "\f616"; }
.i-img-card:before { content: "\f617"; }
.i-img-cd:before { content: "\f618"; }
.i-img-circle:before { content: "\f619"; }
.i-img-computer:before { content: "\f61a"; }
.i-img-cross:before { content: "\f61b"; }
.i-img-date:before { content: "\f61c"; }
.i-img-distort:before { content: "\f61d"; }
.i-img-fields-album:before { content: "\f61e"; }
.i-img-fields:before { content: "\f61f"; }
.i-img-flip-2:before { content: "\f620"; }
.i-img-flip:before { content: "\f621"; }
.i-img-ios-album:before { content: "\f622"; }
.i-img-ios-frame:before { content: "\f623"; }
.i-img-ios:before { content: "\f624"; }
.i-img-laptop:before { content: "\f625"; }
.i-img-location:before { content: "\f626"; }
.i-img-love:before { content: "\f627"; }
.i-img-mask:before { content: "\f628"; }
.i-img-minus:before { content: "\f629"; }
.i-img-panoramic:before { content: "\f62a"; }
.i-img-perspective-2:before { content: "\f62b"; }
.i-img-perspective:before { content: "\f62c"; }
.i-img-phone:before { content: "\f62d"; }
.i-img-plus:before { content: "\f62e"; }
.i-img-portrait:before { content: "\f62f"; }
.i-img-rotate-2:before { content: "\f630"; }
.i-img-rotate:before { content: "\f631"; }
.i-img-save:before { content: "\f632"; }
.i-img-scale-down:before { content: "\f633"; }
.i-img-scale-up:before { content: "\f634"; }
.i-img-scale:before { content: "\f635"; }
.i-img-search:before { content: "\f636"; }
.i-img-settings:before { content: "\f637"; }
.i-img-square:before { content: "\f638"; }
.i-img-tick:before { content: "\f639"; }
.i-img-usb:before { content: "\f63a"; }
.i-img-window:before { content: "\f63b"; }
.i-img:before { content: "\f63c"; }
.i-ui-anchor:before { content: "\f63d"; }
.i-ui-arrange:before { content: "\f63e"; }
.i-ui-battery-block:before { content: "\f63f"; }
.i-ui-battery-charge:before { content: "\f640"; }
.i-ui-battery-full:before { content: "\f641"; }
.i-ui-battery-high:before { content: "\f642"; }
.i-ui-battery-low:before { content: "\f643"; }
.i-ui-battery-med:before { content: "\f644"; }
.i-ui-battery-power:before { content: "\f645"; }
.i-ui-block:before { content: "\f646"; }
.i-ui-bullets:before { content: "\f647"; }
.i-ui-button:before { content: "\f648"; }
.i-ui-check:before { content: "\f649"; }
.i-ui-checkbox-list-2:before { content: "\f64a"; }
.i-ui-checkbox-list:before { content: "\f64b"; }
.i-ui-checkboxes-2:before { content: "\f64c"; }
.i-ui-checkboxes:before { content: "\f64d"; }
.i-ui-command:before { content: "\f64e"; }
.i-ui-cover:before { content: "\f64f"; }
.i-ui-cross-2:before { content: "\f650"; }
.i-ui-cross:before { content: "\f651"; }
.i-ui-cursor:before { content: "\f652"; }
.i-ui-delete:before { content: "\f653"; }
.i-ui-dropdown-2:before { content: "\f654"; }
.i-ui-dropdown:before { content: "\f655"; }
.i-ui-field-search:before { content: "\f656"; }
.i-ui-field-text:before { content: "\f657"; }
.i-ui-fingerprint:before { content: "\f658"; }
.i-ui-jump:before { content: "\f659"; }
.i-ui-link-2:before { content: "\f65a"; }
.i-ui-link-broken-2:before { content: "\f65b"; }
.i-ui-link-broken:before { content: "\f65c"; }
.i-ui-link-cross:before { content: "\f65d"; }
.i-ui-link-minus:before { content: "\f65e"; }
.i-ui-link-plus:before { content: "\f65f"; }
.i-ui-link-tick:before { content: "\f660"; }
.i-ui-link:before { content: "\f661"; }
.i-ui-list:before { content: "\f662"; }
.i-ui-menu-circle:before { content: "\f663"; }
.i-ui-menu-rounded:before { content: "\f664"; }
.i-ui-menu:before { content: "\f665"; }
.i-ui-progress:before { content: "\f666"; }
.i-ui-radios-2:before { content: "\f667"; }
.i-ui-radios:before { content: "\f668"; }
.i-ui-select:before { content: "\f669"; }
.i-ui-signin:before { content: "\f66a"; }
.i-ui-signout:before { content: "\f66b"; }
.i-ui-sliders:before { content: "\f66c"; }
.i-ui-switch:before { content: "\f66d"; }
.i-ui-tab:before { content: "\f66e"; }
.i-ui-tick-2:before { content: "\f66f"; }
.i-ui-tick:before { content: "\f670"; }
.i-ui-tiles:before { content: "\f671"; }
.i-ui-toggle:before { content: "\f672"; }
.i-ui-toggles:before { content: "\f673"; }
.i-ui-touch-no:before { content: "\f674"; }
.i-ui-touch:before { content: "\f675"; }
.i-ui-volume:before { content: "\f676"; }
.i-ui-window-2:before { content: "\f677"; }
.i-ui-window-3:before { content: "\f678"; }
.i-ui-window-bar-mac:before { content: "\f679"; }
.i-ui-window-bar:before { content: "\f67a"; }
.i-ui-window-buttons-mac:before { content: "\f67b"; }
.i-ui-window-buttons:before { content: "\f67c"; }
.i-ui-window-code:before { content: "\f67d"; }
.i-ui-window-cross:before { content: "\f67e"; }
.i-ui-window-delete:before { content: "\f67f"; }
.i-ui-window-internet:before { content: "\f680"; }
.i-ui-window-minus:before { content: "\f681"; }
.i-ui-window-plus:before { content: "\f682"; }
.i-ui-window-protect:before { content: "\f683"; }
.i-ui-window-secure:before { content: "\f684"; }
.i-ui-window-settings:before { content: "\f685"; }
.i-ui-window-stats:before { content: "\f686"; }
.i-ui-window-tick:before { content: "\f687"; }
.i-ui-window-warning:before { content: "\f688"; }
.i-ui-window-web:before { content: "\f689"; }
.i-ui-window:before { content: "\f68a"; }
.i-ui-windows-2:before { content: "\f68b"; }
.i-ui-windows:before { content: "\f68c"; }
.i-net-cable-2:before { content: "\f68d"; }
.i-net-cable:before { content: "\f68e"; }
.i-net-closed:before { content: "\f68f"; }
.i-net-computer:before { content: "\f690"; }
.i-net-connection-folder:before { content: "\f691"; }
.i-net-connection:before { content: "\f692"; }
.i-net-cross:before { content: "\f693"; }
.i-net-download-2:before { content: "\f694"; }
.i-net-download:before { content: "\f695"; }
.i-net-edit:before { content: "\f696"; }
.i-net-fibre:before { content: "\f697"; }
.i-net-folder:before { content: "\f698"; }
.i-net-internet:before { content: "\f699"; }
.i-net-line:before { content: "\f69a"; }
.i-net-link:before { content: "\f69b"; }
.i-net-minus:before { content: "\f69c"; }
.i-net-network:before { content: "\f69d"; }
.i-net-open:before { content: "\f69e"; }
.i-net-phone:before { content: "\f69f"; }
.i-net-plus:before { content: "\f6a0"; }
.i-net-protect:before { content: "\f6a1"; }
.i-net-search:before { content: "\f6a2"; }
.i-net-secure:before { content: "\f6a3"; }
.i-net-settings:before { content: "\f6a4"; }
.i-net-speed:before { content: "\f6a5"; }
.i-net-sync:before { content: "\f6a6"; }
.i-net-tick:before { content: "\f6a7"; }
.i-net-tree:before { content: "\f6a8"; }
.i-net-upload-2:before { content: "\f6a9"; }
.i-net-upload:before { content: "\f6aa"; }
.i-net-warning:before { content: "\f6ab"; }
.i-net-wifi-computer:before { content: "\f6ac"; }
.i-net-wifi-cross:before { content: "\f6ad"; }
.i-net-wifi-edit:before { content: "\f6ae"; }
.i-net-wifi-laptop:before { content: "\f6af"; }
.i-net-wifi-minus:before { content: "\f6b0"; }
.i-net-wifi-phone:before { content: "\f6b1"; }
.i-net-wifi-plus:before { content: "\f6b2"; }
.i-net-wifi-protect:before { content: "\f6b3"; }
.i-net-wifi-router:before { content: "\f6b4"; }
.i-net-wifi-search:before { content: "\f6b5"; }
.i-net-wifi-secure:before { content: "\f6b6"; }
.i-net-wifi-settings:before { content: "\f6b7"; }
.i-net-wifi-signal-2:before { content: "\f6b8"; }
.i-net-wifi-signal-3:before { content: "\f6b9"; }
.i-net-wifi-signal-4:before { content: "\f6ba"; }
.i-net-wifi-signal:before { content: "\f6bb"; }
.i-net-wifi-tick:before { content: "\f6bc"; }
.i-net-wifi-transmitter:before { content: "\f6bd"; }
.i-net-wifi-warning:before { content: "\f6be"; }
.i-kit-blender:before { content: "\f6bf"; }
.i-kit-butter:before { content: "\f6c0"; }
.i-kit-carving-knife:before { content: "\f6c1"; }
.i-kit-coffee-maker:before { content: "\f6c2"; }
.i-kit-colander:before { content: "\f6c3"; }
.i-kit-cooker-2:before { content: "\f6c4"; }
.i-kit-cooker:before { content: "\f6c5"; }
.i-kit-cutting-board:before { content: "\f6c6"; }
.i-kit-dishwasher:before { content: "\f6c7"; }
.i-kit-dryer:before { content: "\f6c8"; }
.i-kit-egg-cup:before { content: "\f6c9"; }
.i-kit-extractor:before { content: "\f6ca"; }
.i-kit-fridge-2:before { content: "\f6cb"; }
.i-kit-fridge:before { content: "\f6cc"; }
.i-kit-hat:before { content: "\f6cd"; }
.i-kit-honey-spoon:before { content: "\f6ce"; }
.i-kit-juicer-modern:before { content: "\f6cf"; }
.i-kit-juicer:before { content: "\f6d0"; }
.i-kit-kettle:before { content: "\f6d1"; }
.i-kit-knife-block:before { content: "\f6d2"; }
.i-kit-knife-fork-plate:before { content: "\f6d3"; }
.i-kit-knife-fork-spoon:before { content: "\f6d4"; }
.i-kit-knife:before { content: "\f6d5"; }
.i-kit-knives:before { content: "\f6d6"; }
.i-kit-ladle:before { content: "\f6d7"; }
.i-kit-lid:before { content: "\f6d8"; }
.i-kit-measuring-jug:before { content: "\f6d9"; }
.i-kit-microwave:before { content: "\f6da"; }
.i-kit-mixing-bowl-electric:before { content: "\f6db"; }
.i-kit-mixing-bowl:before { content: "\f6dc"; }
.i-kit-oven-glove:before { content: "\f6dd"; }
.i-kit-pan-boil:before { content: "\f6de"; }
.i-kit-pan-frying:before { content: "\f6df"; }
.i-kit-pan-heat:before { content: "\f6e0"; }
.i-kit-pans:before { content: "\f6e1"; }
.i-kit-pin-spatula:before { content: "\f6e2"; }
.i-kit-pot-heat:before { content: "\f6e3"; }
.i-kit-pot:before { content: "\f6e4"; }
.i-kit-rolling-pin:before { content: "\f6e5"; }
.i-kit-salt-pepper:before { content: "\f6e6"; }
.i-kit-salt:before { content: "\f6e7"; }
.i-kit-scales-2:before { content: "\f6e8"; }
.i-kit-scales-3:before { content: "\f6e9"; }
.i-kit-scales:before { content: "\f6ea"; }
.i-kit-spatula-ladle:before { content: "\f6eb"; }
.i-kit-spatula-spoon:before { content: "\f6ec"; }
.i-kit-toaster:before { content: "\f6ed"; }
.i-kit-washing-machine:before { content: "\f6ee"; }
.i-kit-whisk-electric:before { content: "\f6ef"; }
.i-kit-whisk:before { content: "\f6f0"; }
.i-lay-crop:before { content: "\f6f1"; }
.i-lay-cropping:before { content: "\f6f2"; }
.i-lay-ellipse-marquee:before { content: "\f6f3"; }
.i-lay-horiz-align-centre:before { content: "\f6f4"; }
.i-lay-horiz-align-left:before { content: "\f6f5"; }
.i-lay-horiz-align-right:before { content: "\f6f6"; }
.i-lay-horiz-dist-centre:before { content: "\f6f7"; }
.i-lay-horiz-dist-left:before { content: "\f6f8"; }
.i-lay-horiz-dist-right:before { content: "\f6f9"; }
.i-lay-img-perspective-2:before { content: "\f6fa"; }
.i-lay-img-perspective:before { content: "\f6fb"; }
.i-lay-img-scale:before { content: "\f6fc"; }
.i-lay-img-skew:before { content: "\f6fd"; }
.i-lay-lasso-tool:before { content: "\f6fe"; }
.i-lay-path-arc:before { content: "\f6ff"; }
.i-lay-path-circle:before { content: "\f700"; }
.i-lay-path-curve:before { content: "\f701"; }
.i-lay-path-hexagon:before { content: "\f702"; }
.i-lay-path-intersect:before { content: "\f703"; }
.i-lay-path-line:before { content: "\f704"; }
.i-lay-path-minus:before { content: "\f705"; }
.i-lay-path-node-add:before { content: "\f706"; }
.i-lay-path-node-minus:before { content: "\f707"; }
.i-lay-path-node:before { content: "\f708"; }
.i-lay-path-pentagon:before { content: "\f709"; }
.i-lay-path-scale:before { content: "\f70a"; }
.i-lay-path-square:before { content: "\f70b"; }
.i-lay-path-triangle:before { content: "\f70c"; }
.i-lay-path-unite:before { content: "\f70d"; }
.i-lay-rectangle-marquee:before { content: "\f70e"; }
.i-lay-reflect-horizontal:before { content: "\f70f"; }
.i-lay-reflect-vertical:before { content: "\f710"; }
.i-lay-transform-centre:before { content: "\f711"; }
.i-lay-transform:before { content: "\f712"; }
.i-lay-vert-align-bottom:before { content: "\f713"; }
.i-lay-vert-align-centre:before { content: "\f714"; }
.i-lay-vert-align-top:before { content: "\f715"; }
.i-lay-vert-dist-bottom:before { content: "\f716"; }
.i-lay-vert-dist-centre:before { content: "\f717"; }
.i-lay-vert-dist-top:before { content: "\f718"; }
.i-loc-arrow:before { content: "\f719"; }
.i-loc-baggage:before { content: "\f71a"; }
.i-loc-binoculars-2:before { content: "\f71b"; }
.i-loc-binoculars:before { content: "\f71c"; }
.i-loc-compass-old:before { content: "\f71d"; }
.i-loc-compass:before { content: "\f71e"; }
.i-loc-direction-symbol:before { content: "\f71f"; }
.i-loc-divider:before { content: "\f720"; }
.i-loc-east:before { content: "\f721"; }
.i-loc-globe:before { content: "\f722"; }
.i-loc-latitude:before { content: "\f723"; }
.i-loc-longitude:before { content: "\f724"; }
.i-loc-map-2:before { content: "\f725"; }
.i-loc-map-compass:before { content: "\f726"; }
.i-loc-map-route:before { content: "\f727"; }
.i-loc-map:before { content: "\f728"; }
.i-loc-north:before { content: "\f729"; }
.i-loc-pin-2:before { content: "\f72a"; }
.i-loc-pin-angle-2:before { content: "\f72b"; }
.i-loc-pin-angle:before { content: "\f72c"; }
.i-loc-pin-distance-2:before { content: "\f72d"; }
.i-loc-pin-distance:before { content: "\f72e"; }
.i-loc-pin-map-2:before { content: "\f72f"; }
.i-loc-pin-map:before { content: "\f730"; }
.i-loc-pin-radius-2:before { content: "\f731"; }
.i-loc-pin-radius:before { content: "\f732"; }
.i-loc-pin:before { content: "\f733"; }
.i-loc-point-cross:before { content: "\f734"; }
.i-loc-point-distance:before { content: "\f735"; }
.i-loc-point-heart:before { content: "\f736"; }
.i-loc-point-map:before { content: "\f737"; }
.i-loc-point-minus:before { content: "\f738"; }
.i-loc-point-plus:before { content: "\f739"; }
.i-loc-point-radius:before { content: "\f73a"; }
.i-loc-point-search:before { content: "\f73b"; }
.i-loc-point-secure:before { content: "\f73c"; }
.i-loc-point-settings:before { content: "\f73d"; }
.i-loc-point-star:before { content: "\f73e"; }
.i-loc-point-target:before { content: "\f73f"; }
.i-loc-point-tick:before { content: "\f740"; }
.i-loc-point:before { content: "\f741"; }
.i-loc-points:before { content: "\f742"; }
.i-loc-route:before { content: "\f743"; }
.i-loc-search-code:before { content: "\f744"; }
.i-loc-search-cross:before { content: "\f745"; }
.i-loc-search-location:before { content: "\f746"; }
.i-loc-search-love:before { content: "\f747"; }
.i-loc-search-minus:before { content: "\f748"; }
.i-loc-search-person:before { content: "\f749"; }
.i-loc-search-plus:before { content: "\f74a"; }
.i-loc-search-secure:before { content: "\f74b"; }
.i-loc-search-star:before { content: "\f74c"; }
.i-loc-search-tick:before { content: "\f74d"; }
.i-loc-sextant:before { content: "\f74e"; }
.i-loc-sign-warning:before { content: "\f74f"; }
.i-loc-sign:before { content: "\f750"; }
.i-loc-signpost-2:before { content: "\f751"; }
.i-loc-signpost-3:before { content: "\f752"; }
.i-loc-signpost:before { content: "\f753"; }
.i-loc-south:before { content: "\f754"; }
.i-loc-telescope:before { content: "\f755"; }
.i-loc-west:before { content: "\f756"; }
.i-mov-3d-glasses:before { content: "\f757"; }
.i-mov-award-2:before { content: "\f758"; }
.i-mov-award:before { content: "\f759"; }
.i-mov-bluray:before { content: "\f75a"; }
.i-mov-camera-2:before { content: "\f75b"; }
.i-mov-camera-3:before { content: "\f75c"; }
.i-mov-camera-4:before { content: "\f75d"; }
.i-mov-camera-movie:before { content: "\f75e"; }
.i-mov-camera-off:before { content: "\f75f"; }
.i-mov-camera-old:before { content: "\f760"; }
.i-mov-camera:before { content: "\f761"; }
.i-mov-cd-movie-2:before { content: "\f762"; }
.i-mov-chair:before { content: "\f763"; }
.i-mov-cinema:before { content: "\f764"; }
.i-mov-clapper-2:before { content: "\f765"; }
.i-mov-clapper-3:before { content: "\f766"; }
.i-mov-clapper:before { content: "\f767"; }
.i-mov-computer:before { content: "\f768"; }
.i-mov-countdown-2:before { content: "\f769"; }
.i-mov-countdown:before { content: "\f76a"; }
.i-mov-cover:before { content: "\f76b"; }
.i-mov-devices:before { content: "\f76c"; }
.i-mov-drama:before { content: "\f76d"; }
.i-mov-dvd-case:before { content: "\f76e"; }
.i-mov-dvd-movie:before { content: "\f76f"; }
.i-mov-dvd:before { content: "\f770"; }
.i-mov-eject:before { content: "\f771"; }
.i-mov-end:before { content: "\f772"; }
.i-mov-forward:before { content: "\f773"; }
.i-mov-go-pro:before { content: "\f774"; }
.i-mov-hd:before { content: "\f775"; }
.i-mov-laptop:before { content: "\f776"; }
.i-mov-lighting-2:before { content: "\f777"; }
.i-mov-megaphone:before { content: "\f778"; }
.i-mov-mirror:before { content: "\f779"; }
.i-mov-movie-attach:before { content: "\f77a"; }
.i-mov-movie-cross:before { content: "\f77b"; }
.i-mov-movie-date:before { content: "\f77c"; }
.i-mov-movie-location:before { content: "\f77d"; }
.i-mov-movie-love:before { content: "\f77e"; }
.i-mov-movie-minus:before { content: "\f77f"; }
.i-mov-movie-plus:before { content: "\f780"; }
.i-mov-movie-search:before { content: "\f781"; }
.i-mov-movie-settings:before { content: "\f782"; }
.i-mov-movie-tick:before { content: "\f783"; }
.i-mov-movie:before { content: "\f784"; }
.i-mov-next:before { content: "\f785"; }
.i-mov-pause:before { content: "\f786"; }
.i-mov-phone:before { content: "\f787"; }
.i-mov-play:before { content: "\f788"; }
.i-mov-player-dvd:before { content: "\f789"; }
.i-mov-player-vhs:before { content: "\f78a"; }
.i-mov-previous:before { content: "\f78b"; }
.i-mov-reel-2:before { content: "\f78c"; }
.i-mov-reel:before { content: "\f78d"; }
.i-mov-remote-2:before { content: "\f78e"; }
.i-mov-remote:before { content: "\f78f"; }
.i-mov-reverse:before { content: "\f790"; }
.i-mov-rewind:before { content: "\f791"; }
.i-mov-screen:before { content: "\f792"; }
.i-mov-sd:before { content: "\f793"; }
.i-mov-show:before { content: "\f794"; }
.i-mov-slider-side:before { content: "\f795"; }
.i-mov-slider:before { content: "\f796"; }
.i-mov-star:before { content: "\f797"; }
.i-mov-start:before { content: "\f798"; }
.i-mov-stop:before { content: "\f799"; }
.i-mov-tablet:before { content: "\f79a"; }
.i-mov-ticket:before { content: "\f79b"; }
.i-mov-tv-3d:before { content: "\f79c"; }
.i-mov-tv-4k:before { content: "\f79d"; }
.i-mov-tv-curved:before { content: "\f79e"; }
.i-mov-tv-internet:before { content: "\f79f"; }
.i-mov-tv-movie:before { content: "\f7a0"; }
.i-mov-tv-old:before { content: "\f7a1"; }
.i-mov-tv-size:before { content: "\f7a2"; }
.i-mov-tv-wifi:before { content: "\f7a3"; }
.i-mov-tv:before { content: "\f7a4"; }
.i-mov-usb:before { content: "\f7a5"; }
.i-mov-vhs:before { content: "\f7a6"; }
.i-mus-book-audio:before { content: "\f7a7"; }
.i-mus-book-music:before { content: "\f7a8"; }
.i-mus-cd-audio:before { content: "\f7a9"; }
.i-mus-cd-case:before { content: "\f7aa"; }
.i-mus-cd-mp3:before { content: "\f7ab"; }
.i-mus-cd-music:before { content: "\f7ac"; }
.i-mus-cd:before { content: "\f7ad"; }
.i-mus-cover:before { content: "\f7ae"; }
.i-mus-dial:before { content: "\f7af"; }
.i-mus-digital-radio:before { content: "\f7b0"; }
.i-mus-drum-sticks:before { content: "\f7b1"; }
.i-mus-drum:before { content: "\f7b2"; }
.i-mus-drums:before { content: "\f7b3"; }
.i-mus-eject:before { content: "\f7b4"; }
.i-mus-end:before { content: "\f7b5"; }
.i-mus-equaliser:before { content: "\f7b6"; }
.i-mus-forward:before { content: "\f7b7"; }
.i-mus-french-horn:before { content: "\f7b8"; }
.i-mus-guitar-electric:before { content: "\f7b9"; }
.i-mus-guitar:before { content: "\f7ba"; }
.i-mus-harp:before { content: "\f7bb"; }
.i-mus-headphones:before { content: "\f7bc"; }
.i-mus-ipod-earbuds:before { content: "\f7bd"; }
.i-mus-ipod-headphones:before { content: "\f7be"; }
.i-mus-ipod-nano:before { content: "\f7bf"; }
.i-mus-ipod:before { content: "\f7c0"; }
.i-mus-levels:before { content: "\f7c1"; }
.i-mus-maracas:before { content: "\f7c2"; }
.i-mus-mic-2:before { content: "\f7c3"; }
.i-mus-mic:before { content: "\f7c4"; }
.i-mus-music-case:before { content: "\f7c5"; }
.i-mus-mute:before { content: "\f7c6"; }
.i-mus-next:before { content: "\f7c7"; }
.i-mus-note-2:before { content: "\f7c8"; }
.i-mus-note-3:before { content: "\f7c9"; }
.i-mus-note:before { content: "\f7ca"; }
.i-mus-pan-pipes:before { content: "\f7cb"; }
.i-mus-pause:before { content: "\f7cc"; }
.i-mus-piano-keys:before { content: "\f7cd"; }
.i-mus-piano:before { content: "\f7ce"; }
.i-mus-play:before { content: "\f7cf"; }
.i-mus-player-old:before { content: "\f7d0"; }
.i-mus-player:before { content: "\f7d1"; }
.i-mus-plug:before { content: "\f7d2"; }
.i-mus-previous:before { content: "\f7d3"; }
.i-mus-radio-mic-off:before { content: "\f7d4"; }
.i-mus-radio-mic-old:before { content: "\f7d5"; }
.i-mus-radio-mic:before { content: "\f7d6"; }
.i-mus-radio:before { content: "\f7d7"; }
.i-mus-record-music:before { content: "\f7d8"; }
.i-mus-record:before { content: "\f7d9"; }
.i-mus-reverse:before { content: "\f7da"; }
.i-mus-rewind:before { content: "\f7db"; }
.i-mus-saxophone:before { content: "\f7dc"; }
.i-mus-slider-side:before { content: "\f7dd"; }
.i-mus-slider:before { content: "\f7de"; }
.i-mus-sound-2:before { content: "\f7df"; }
.i-mus-sound:before { content: "\f7e0"; }
.i-mus-speaker-2:before { content: "\f7e1"; }
.i-mus-speaker:before { content: "\f7e2"; }
.i-mus-speakers:before { content: "\f7e3"; }
.i-mus-start:before { content: "\f7e4"; }
.i-mus-stereo:before { content: "\f7e5"; }
.i-mus-stop:before { content: "\f7e6"; }
.i-mus-tape:before { content: "\f7e7"; }
.i-mus-triangle:before { content: "\f7e8"; }
.i-mus-trumpet:before { content: "\f7e9"; }
.i-mus-tuning:before { content: "\f7ea"; }
.i-mus-vol-high:before { content: "\f7eb"; }
.i-mus-vol-low:before { content: "\f7ec"; }
.i-mus-vol-med:before { content: "\f7ed"; }
.i-mus-xylophone:before { content: "\f7ee"; }
.i-ios-airdrop:before { content: "\f7ef"; }
.i-ios-alarms:before { content: "\f7f0"; }
.i-ios-albums:before { content: "\f7f1"; }
.i-ios-bookmarks:before { content: "\f7f2"; }
.i-ios-browsers:before { content: "\f7f3"; }
.i-ios-contact:before { content: "\f7f4"; }
.i-ios-download:before { content: "\f7f5"; }
.i-ios-favorites:before { content: "\f7f6"; }
.i-ios-films:before { content: "\f7f7"; }
.i-ios-flags:before { content: "\f7f8"; }
.i-ios-keypad:before { content: "\f7f9"; }
.i-ios-messages:before { content: "\f7fa"; }
.i-ios-more:before { content: "\f7fb"; }
.i-ios-near:before { content: "\f7fc"; }
.i-ios-new:before { content: "\f7fd"; }
.i-ios-next:before { content: "\f7fe"; }
.i-ios-photos:before { content: "\f7ff"; }
.i-ios-playlists:before { content: "\f800"; }
.i-ios-previous:before { content: "\f801"; }
.i-ios-reading:before { content: "\f802"; }
.i-ios-recents:before { content: "\f803"; }
.i-ios-reply:before { content: "\f804"; }
.i-ios-search:before { content: "\f805"; }
.i-ios-stop-watch:before { content: "\f806"; }
.i-ios-television:before { content: "\f807"; }
.i-ios-timer:before { content: "\f808"; }
.i-ios-top-charts:before { content: "\f809"; }
.i-ios-trash:before { content: "\f80a"; }
.i-ios-upload:before { content: "\f80b"; }
.i-ios-world-times:before { content: "\f80c"; }
.i-peo-astronaut:before { content: "\f80d"; }
.i-peo-banker:before { content: "\f80e"; }
.i-peo-boy:before { content: "\f80f"; }
.i-peo-builder:before { content: "\f810"; }
.i-peo-caller-female:before { content: "\f811"; }
.i-peo-caller-male:before { content: "\f812"; }
.i-peo-chef-female:before { content: "\f813"; }
.i-peo-chef-male:before { content: "\f814"; }
.i-peo-doctor:before { content: "\f815"; }
.i-peo-evening-dress-female:before { content: "\f816"; }
.i-peo-evening-dress-male:before { content: "\f817"; }
.i-peo-fighter-pilot:before { content: "\f818"; }
.i-peo-girl:before { content: "\f819"; }
.i-peo-graduate-female:before { content: "\f81a"; }
.i-peo-graduate-male:before { content: "\f81b"; }
.i-peo-lawyer-female:before { content: "\f81c"; }
.i-peo-lawyer-male:before { content: "\f81d"; }
.i-peo-nude-female:before { content: "\f81e"; }
.i-peo-nude-male:before { content: "\f81f"; }
.i-peo-nun:before { content: "\f820"; }
.i-peo-nurse:before { content: "\f821"; }
.i-peo-office-worker-female:before { content: "\f822"; }
.i-peo-office-worker-male:before { content: "\f823"; }
.i-peo-pilot:before { content: "\f824"; }
.i-peo-police-female:before { content: "\f825"; }
.i-peo-police-male:before { content: "\f826"; }
.i-peo-priest:before { content: "\f827"; }
.i-peo-scientist-female:before { content: "\f828"; }
.i-peo-scientist-male:before { content: "\f829"; }
.i-peo-shopkeeper-female:before { content: "\f82a"; }
.i-peo-shopkeeper-male:before { content: "\f82b"; }
.i-peo-soldier:before { content: "\f82c"; }
.i-peo-surgeon:before { content: "\f82d"; }
.i-pho-1-1:before { content: "\f82e"; }
.i-pho-16-9:before { content: "\f82f"; }
.i-pho-4-3:before { content: "\f830"; }
.i-pho-aperture-minus:before { content: "\f831"; }
.i-pho-aperture-plus:before { content: "\f832"; }
.i-pho-aperture:before { content: "\f833"; }
.i-pho-brightness-contrast:before { content: "\f834"; }
.i-pho-brightness:before { content: "\f835"; }
.i-pho-camera-compact:before { content: "\f836"; }
.i-pho-camera-off:before { content: "\f837"; }
.i-pho-camera-pinhole:before { content: "\f838"; }
.i-pho-camera-polaroid:before { content: "\f839"; }
.i-pho-camera-slr:before { content: "\f83a"; }
.i-pho-camera-tripod:before { content: "\f83b"; }
.i-pho-camera:before { content: "\f83c"; }
.i-pho-compose:before { content: "\f83d"; }
.i-pho-contrast:before { content: "\f83e"; }
.i-pho-film:before { content: "\f83f"; }
.i-pho-filter:before { content: "\f840"; }
.i-pho-filters:before { content: "\f841"; }
.i-pho-flash-auto:before { content: "\f842"; }
.i-pho-flash-off:before { content: "\f843"; }
.i-pho-flash-pro:before { content: "\f844"; }
.i-pho-flash:before { content: "\f845"; }
.i-pho-flip:before { content: "\f846"; }
.i-pho-iso:before { content: "\f847"; }
.i-pho-landscape:before { content: "\f848"; }
.i-pho-lens:before { content: "\f849"; }
.i-pho-lighting:before { content: "\f84a"; }
.i-pho-macro-album:before { content: "\f84b"; }
.i-pho-macro-photo:before { content: "\f84c"; }
.i-pho-macro:before { content: "\f84d"; }
.i-pho-meter:before { content: "\f84e"; }
.i-pho-photos:before { content: "\f84f"; }
.i-pho-plus-minus:before { content: "\f850"; }
.i-pho-polaroid:before { content: "\f851"; }
.i-pho-polaroids:before { content: "\f852"; }
.i-pho-portrait-album:before { content: "\f853"; }
.i-pho-portrait-photo:before { content: "\f854"; }
.i-pho-portrait:before { content: "\f855"; }
.i-pho-preview:before { content: "\f856"; }
.i-pho-quick-shoot:before { content: "\f857"; }
.i-pho-redeye-off:before { content: "\f858"; }
.i-pho-redeye:before { content: "\f859"; }
.i-pho-shadow-2:before { content: "\f85a"; }
.i-pho-shadow:before { content: "\f85b"; }
.i-pho-slr-flash:before { content: "\f85c"; }
.i-pho-timer:before { content: "\f85d"; }
.i-pho-tripod:before { content: "\f85e"; }
.i-pho-umbrella:before { content: "\f85f"; }
.i-pre-bar-chart:before { content: "\f860"; }
.i-pre-bar-graph:before { content: "\f861"; }
.i-pre-bar-stacked-graph:before { content: "\f862"; }
.i-pre-board-bar:before { content: "\f863"; }
.i-pre-board-pie:before { content: "\f864"; }
.i-pre-board:before { content: "\f865"; }
.i-pre-briefcase:before { content: "\f866"; }
.i-pre-chart-down:before { content: "\f867"; }
.i-pre-chart-up:before { content: "\f868"; }
.i-pre-col-graph-decrease:before { content: "\f869"; }
.i-pre-col-graph-increase:before { content: "\f86a"; }
.i-pre-col-graph:before { content: "\f86b"; }
.i-pre-col-stacked-graph:before { content: "\f86c"; }
.i-pre-computer-charts:before { content: "\f86d"; }
.i-pre-doc-graph-2:before { content: "\f86e"; }
.i-pre-doc-graph:before { content: "\f86f"; }
.i-pre-doc-pie-chart-2:before { content: "\f870"; }
.i-pre-doc-pie-chart:before { content: "\f871"; }
.i-pre-laptop-charts:before { content: "\f872"; }
.i-pre-line-graph:before { content: "\f873"; }
.i-pre-mobile-charts:before { content: "\f874"; }
.i-pre-node-bar-graph:before { content: "\f875"; }
.i-pre-node-graph:before { content: "\f876"; }
.i-pre-pie-chart-2:before { content: "\f877"; }
.i-pre-pie-chart-3d:before { content: "\f878"; }
.i-pre-pie-chart:before { content: "\f879"; }
.i-pre-podium-audience:before { content: "\f87a"; }
.i-pre-podium-mic:before { content: "\f87b"; }
.i-pre-presentation-audience:before { content: "\f87c"; }
.i-pre-presenter-audience:before { content: "\f87d"; }
.i-pre-presenter-board:before { content: "\f87e"; }
.i-pre-presenter-graph:before { content: "\f87f"; }
.i-pre-presenter-increase:before { content: "\f880"; }
.i-pre-presenter-pie:before { content: "\f881"; }
.i-pre-presenter-podium:before { content: "\f882"; }
.i-pre-presenter-point:before { content: "\f883"; }
.i-pre-projector:before { content: "\f884"; }
.i-pre-satchel:before { content: "\f885"; }
.i-pre-scatter-graph:before { content: "\f886"; }
.i-pre-venn-diagram:before { content: "\f887"; }
.i-sec-alarmbell-ring:before { content: "\f888"; }
.i-sec-alarmbell:before { content: "\f889"; }
.i-sec-barbed-wire:before { content: "\f88a"; }
.i-sec-baton:before { content: "\f88b"; }
.i-sec-cctv:before { content: "\f88c"; }
.i-sec-combination-lock-open:before { content: "\f88d"; }
.i-sec-combination-lock:before { content: "\f88e"; }
.i-sec-court:before { content: "\f88f"; }
.i-sec-gavel:before { content: "\f890"; }
.i-sec-handcuffs:before { content: "\f891"; }
.i-sec-handle-halt:before { content: "\f892"; }
.i-sec-handle-sign:before { content: "\f893"; }
.i-sec-id-neck:before { content: "\f894"; }
.i-sec-id:before { content: "\f895"; }
.i-sec-key:before { content: "\f896"; }
.i-sec-padlock-closed-2:before { content: "\f897"; }
.i-sec-padlock-closed:before { content: "\f898"; }
.i-sec-padlock-key:before { content: "\f899"; }
.i-sec-padlock-open-2:before { content: "\f89a"; }
.i-sec-padlock-open:before { content: "\f89b"; }
.i-sec-police-badge:before { content: "\f89c"; }
.i-sec-police-hat:before { content: "\f89d"; }
.i-sec-police-helmet:before { content: "\f89e"; }
.i-sec-prison-door:before { content: "\f89f"; }
.i-sec-prisoner:before { content: "\f8a0"; }
.i-sec-safe:before { content: "\f8a1"; }
.i-sec-scales-justice-tipped:before { content: "\f8a2"; }
.i-sec-scales-justice:before { content: "\f8a3"; }
.i-sec-sheriff-badge:before { content: "\f8a4"; }
.i-sec-shield-cross:before { content: "\f8a5"; }
.i-sec-shield-diagonal:before { content: "\f8a6"; }
.i-sec-shield:before { content: "\f8a7"; }
.i-sec-siren:before { content: "\f8a8"; }
.i-sec-truncheon:before { content: "\f8a9"; }
.i-sec-walkie-talkie:before { content: "\f8aa"; }
.i-sec-walkie-talkies:before { content: "\f8ab"; }
.i-sec-watch-tower:before { content: "\f8ac"; }
.i-sha-circle-triangle-square:before { content: "\f8ad"; }
.i-sha-circle:before { content: "\f8ae"; }
.i-sha-cone-2:before { content: "\f8af"; }
.i-sha-cone:before { content: "\f8b0"; }
.i-sha-cube-2:before { content: "\f8b1"; }
.i-sha-cube:before { content: "\f8b2"; }
.i-sha-cubes:before { content: "\f8b3"; }
.i-sha-cylinder-2:before { content: "\f8b4"; }
.i-sha-cylinder:before { content: "\f8b5"; }
.i-sha-dodecahedron-2:before { content: "\f8b6"; }
.i-sha-dodecahedron:before { content: "\f8b7"; }
.i-sha-ellipse:before { content: "\f8b8"; }
.i-sha-hexagon-cylinder-2:before { content: "\f8b9"; }
.i-sha-hexagon-cylinder:before { content: "\f8ba"; }
.i-sha-hexagon:before { content: "\f8bb"; }
.i-sha-pentagon:before { content: "\f8bc"; }
.i-sha-pyramid-2:before { content: "\f8bd"; }
.i-sha-pyramid:before { content: "\f8be"; }
.i-sha-rect-rounded-cylinder-2:before { content: "\f8bf"; }
.i-sha-rect-rounded-cylinder:before { content: "\f8c0"; }
.i-sha-rect-rounded:before { content: "\f8c1"; }
.i-sha-sphere-cone-cube:before { content: "\f8c2"; }
.i-sha-sphere:before { content: "\f8c3"; }
.i-sha-spheres:before { content: "\f8c4"; }
.i-sha-square:before { content: "\f8c5"; }
.i-sha-star-cylinder-2:before { content: "\f8c6"; }
.i-sha-star-cylinder:before { content: "\f8c7"; }
.i-sha-star:before { content: "\f8c8"; }
.i-sha-triangle:before { content: "\f8c9"; }
.i-sho-bag-2:before { content: "\f8ca"; }
.i-sho-bag:before { content: "\f8cb"; }
.i-sho-basket-cross:before { content: "\f8cc"; }
.i-sho-basket-love:before { content: "\f8cd"; }
.i-sho-basket-minus:before { content: "\f8ce"; }
.i-sho-basket-plus:before { content: "\f8cf"; }
.i-sho-basket-search:before { content: "\f8d0"; }
.i-sho-basket-tick:before { content: "\f8d1"; }
.i-sho-basket-warning:before { content: "\f8d2"; }
.i-sho-basket:before { content: "\f8d3"; }
.i-sho-box-closed:before { content: "\f8d4"; }
.i-sho-box-open:before { content: "\f8d5"; }
.i-sho-box-sealed-up:before { content: "\f8d6"; }
.i-sho-box-sealed:before { content: "\f8d7"; }
.i-sho-cart-add:before { content: "\f8d8"; }
.i-sho-cart-remove:before { content: "\f8d9"; }
.i-sho-cart-stall:before { content: "\f8da"; }
.i-sho-cart:before { content: "\f8db"; }
.i-sho-cash-box:before { content: "\f8dc"; }
.i-sho-coat-hanger:before { content: "\f8dd"; }
.i-sho-gift-tall:before { content: "\f8de"; }
.i-sho-gift-wide:before { content: "\f8df"; }
.i-sho-gift:before { content: "\f8e0"; }
.i-sho-market-stall:before { content: "\f8e1"; }
.i-sho-purse:before { content: "\f8e2"; }
.i-sho-sale-half:before { content: "\f8e3"; }
.i-sho-sale-percentage:before { content: "\f8e4"; }
.i-sho-sale-third:before { content: "\f8e5"; }
.i-sho-shop:before { content: "\f8e6"; }
.i-sho-tag-cut:before { content: "\f8e7"; }
.i-sho-tag-dollar:before { content: "\f8e8"; }
.i-sho-tag-euro:before { content: "\f8e9"; }
.i-sho-tag-rupee:before { content: "\f8ea"; }
.i-sho-tag-sterling:before { content: "\f8eb"; }
.i-sho-tag-yen-yuan:before { content: "\f8ec"; }
.i-sho-tag:before { content: "\f8ed"; }
.i-sho-tags:before { content: "\f8ee"; }
.i-sho-till-old:before { content: "\f8ef"; }
.i-sho-till-portable:before { content: "\f8f0"; }
.i-sho-till:before { content: "\f8f1"; }
.i-sho-wallet-coins:before { content: "\f8f2"; }
.i-sho-wallet-open:before { content: "\f8f3"; }
.i-sho-wallet:before { content: "\f8f4"; }
.i-spa-alien-2:before { content: "\f8f5"; }
.i-spa-alien-abduction:before { content: "\f8f6"; }
.i-spa-alien:before { content: "\f8f7"; }
.i-spa-astronaut:before { content: "\f8f8"; }
.i-spa-capsule-entry-2:before { content: "\f8f9"; }
.i-spa-capsule-entry:before { content: "\f8fa"; }
.i-spa-capsule-landing:before { content: "\f8fb"; }
.i-spa-capsule-rocket:before { content: "\f8fc"; }
.i-spa-constellation:before { content: "\f8fd"; }
.i-spa-dalek:before { content: "\f8fe"; }
.i-spa-darkside:before { content: "\f8ff"; }
.i-spa-darth-vader:before { content: "\f900"; }
.i-spa-death-star-2:before { content: "\f901"; }
.i-spa-death-star:before { content: "\f902"; }
.i-spa-dish:before { content: "\f903"; }
.i-spa-earth-moon:before { content: "\f904"; }
.i-spa-earth:before { content: "\f905"; }
.i-spa-enterprise:before { content: "\f906"; }
.i-spa-helmet-astronaut:before { content: "\f907"; }
.i-spa-helmet:before { content: "\f908"; }
.i-spa-jupiter:before { content: "\f909"; }
.i-spa-lander:before { content: "\f90a"; }
.i-spa-launch:before { content: "\f90b"; }
.i-spa-meteor-2:before { content: "\f90c"; }
.i-spa-meteor-orbit:before { content: "\f90d"; }
.i-spa-meteor:before { content: "\f90e"; }
.i-spa-meteors:before { content: "\f90f"; }
.i-spa-moon-earth:before { content: "\f910"; }
.i-spa-moon-flag-2:before { content: "\f911"; }
.i-spa-moon-flag:before { content: "\f912"; }
.i-spa-moon:before { content: "\f913"; }
.i-spa-observatory:before { content: "\f914"; }
.i-spa-orbit-2:before { content: "\f915"; }
.i-spa-orbit:before { content: "\f916"; }
.i-spa-planet:before { content: "\f917"; }
.i-spa-r2d2:before { content: "\f918"; }
.i-spa-ray-gun:before { content: "\f919"; }
.i-spa-robot-2:before { content: "\f91a"; }
.i-spa-robot:before { content: "\f91b"; }
.i-spa-rocket-orbit:before { content: "\f91c"; }
.i-spa-rocket:before { content: "\f91d"; }
.i-spa-rover:before { content: "\f91e"; }
.i-spa-satellite:before { content: "\f91f"; }
.i-spa-saturn:before { content: "\f920"; }
.i-spa-saucer-2:before { content: "\f921"; }
.i-spa-saucer-3:before { content: "\f922"; }
.i-spa-saucer-4:before { content: "\f923"; }
.i-spa-saucer:before { content: "\f924"; }
.i-spa-shooting-star:before { content: "\f925"; }
.i-spa-shuttle-2:before { content: "\f926"; }
.i-spa-shuttle:before { content: "\f927"; }
.i-spa-space-walk:before { content: "\f928"; }
.i-spa-sputnik:before { content: "\f929"; }
.i-spa-star-trek:before { content: "\f92a"; }
.i-spa-stars:before { content: "\f92b"; }
.i-spa-stormtrooper:before { content: "\f92c"; }
.i-spa-telescope:before { content: "\f92d"; }
.i-spa-tie-fighter-2:before { content: "\f92e"; }
.i-spa-tie-fighter:before { content: "\f92f"; }
.i-spa-ufo:before { content: "\f930"; }
.i-spo-archery-target:before { content: "\f931"; }
.i-spo-archery:before { content: "\f932"; }
.i-spo-baseball-bat:before { content: "\f933"; }
.i-spo-baseball-helmet:before { content: "\f934"; }
.i-spo-baseball-pitch:before { content: "\f935"; }
.i-spo-baseball:before { content: "\f936"; }
.i-spo-basketball:before { content: "\f937"; }
.i-spo-board:before { content: "\f938"; }
.i-spo-bowling-ball:before { content: "\f939"; }
.i-spo-boxing:before { content: "\f93a"; }
.i-spo-cricket-2:before { content: "\f93b"; }
.i-spo-cricket:before { content: "\f93c"; }
.i-spo-cycle-helmet:before { content: "\f93d"; }
.i-spo-darts:before { content: "\f93e"; }
.i-spo-fishing:before { content: "\f93f"; }
.i-spo-flag:before { content: "\f940"; }
.i-spo-football-helmet:before { content: "\f941"; }
.i-spo-football-post:before { content: "\f942"; }
.i-spo-football:before { content: "\f943"; }
.i-spo-golf-ball:before { content: "\f944"; }
.i-spo-golf:before { content: "\f945"; }
.i-spo-hockey:before { content: "\f946"; }
.i-spo-hoop:before { content: "\f947"; }
.i-spo-horse:before { content: "\f948"; }
.i-spo-ice-hockey:before { content: "\f949"; }
.i-spo-kurling:before { content: "\f94a"; }
.i-spo-medal:before { content: "\f94b"; }
.i-spo-net:before { content: "\f94c"; }
.i-spo-pin-bowl:before { content: "\f94d"; }
.i-spo-pins:before { content: "\f94e"; }
.i-spo-podium:before { content: "\f94f"; }
.i-spo-pool-ball:before { content: "\f950"; }
.i-spo-rings:before { content: "\f951"; }
.i-spo-rugby-ball:before { content: "\f952"; }
.i-spo-rugby-post:before { content: "\f953"; }
.i-spo-scales:before { content: "\f954"; }
.i-spo-shuttlecock:before { content: "\f955"; }
.i-spo-sneaker:before { content: "\f956"; }
.i-spo-soccer-ball:before { content: "\f957"; }
.i-spo-soccer-pitch:before { content: "\f958"; }
.i-spo-studs:before { content: "\f959"; }
.i-spo-table-tennis:before { content: "\f95a"; }
.i-spo-tennis-ball:before { content: "\f95b"; }
.i-spo-tennis-court:before { content: "\f95c"; }
.i-spo-tennis:before { content: "\f95d"; }
.i-spo-track:before { content: "\f95e"; }
.i-spo-trophy-star:before { content: "\f95f"; }
.i-spo-trophy:before { content: "\f960"; }
.i-spo-weights:before { content: "\f961"; }
.i-spo-whistle:before { content: "\f962"; }
.i-tel-3g:before { content: "\f963"; }
.i-tel-4g:before { content: "\f964"; }
.i-tel-block:before { content: "\f965"; }
.i-tel-bluetooth:before { content: "\f966"; }
.i-tel-cable:before { content: "\f967"; }
.i-tel-calibrate:before { content: "\f968"; }
.i-tel-chat:before { content: "\f969"; }
.i-tel-cordless-base-2:before { content: "\f96a"; }
.i-tel-cordless-base:before { content: "\f96b"; }
.i-tel-cordless-block:before { content: "\f96c"; }
.i-tel-cordless-call:before { content: "\f96d"; }
.i-tel-cordless-cordless:before { content: "\f96e"; }
.i-tel-cordless-plug:before { content: "\f96f"; }
.i-tel-cordless-power:before { content: "\f970"; }
.i-tel-cordless-record:before { content: "\f971"; }
.i-tel-cross:before { content: "\f972"; }
.i-tel-edit:before { content: "\f973"; }
.i-tel-fixed-block:before { content: "\f974"; }
.i-tel-fixed-call:before { content: "\f975"; }
.i-tel-fixed-handset:before { content: "\f976"; }
.i-tel-fixed-new:before { content: "\f977"; }
.i-tel-fixed-receive:before { content: "\f978"; }
.i-tel-fixed-record:before { content: "\f979"; }
.i-tel-fixed-send:before { content: "\f97a"; }
.i-tel-fixed:before { content: "\f97b"; }
.i-tel-flip:before { content: "\f97c"; }
.i-tel-hands-free:before { content: "\f97d"; }
.i-tel-headset-generic:before { content: "\f97e"; }
.i-tel-headset-male:before { content: "\f97f"; }
.i-tel-headset:before { content: "\f980"; }
.i-tel-location:before { content: "\f981"; }
.i-tel-mast:before { content: "\f982"; }
.i-tel-minus:before { content: "\f983"; }
.i-tel-phone-2:before { content: "\f984"; }
.i-tel-phone-computer:before { content: "\f985"; }
.i-tel-phone-mac:before { content: "\f986"; }
.i-tel-phone-phone:before { content: "\f987"; }
.i-tel-phone-tv:before { content: "\f988"; }
.i-tel-phone:before { content: "\f989"; }
.i-tel-plus:before { content: "\f98a"; }
.i-tel-receive:before { content: "\f98b"; }
.i-tel-record:before { content: "\f98c"; }
.i-tel-rotate:before { content: "\f98d"; }
.i-tel-search:before { content: "\f98e"; }
.i-tel-secure:before { content: "\f98f"; }
.i-tel-send:before { content: "\f990"; }
.i-tel-settings:before { content: "\f991"; }
.i-tel-shake:before { content: "\f992"; }
.i-tel-sim:before { content: "\f993"; }
.i-tel-string-cups:before { content: "\f994"; }
.i-tel-tick:before { content: "\f995"; }
.i-tel-warning:before { content: "\f996"; }
.i-tel-wifi-2:before { content: "\f997"; }
.i-tel-wifi:before { content: "\f998"; }
.i-tim-12-hour:before { content: "\f999"; }
.i-tim-24-hour:before { content: "\f99a"; }
.i-tim-add:before { content: "\f99b"; }
.i-tim-alarm-square:before { content: "\f99c"; }
.i-tim-calendar-2:before { content: "\f99d"; }
.i-tim-calendar-edit:before { content: "\f99e"; }
.i-tim-calendar-flag:before { content: "\f99f"; }
.i-tim-calendar-heart:before { content: "\f9a0"; }
.i-tim-calendar-location:before { content: "\f9a1"; }
.i-tim-calendar-locked:before { content: "\f9a2"; }
.i-tim-calendar-minus:before { content: "\f9a3"; }
.i-tim-calendar-plus:before { content: "\f9a4"; }
.i-tim-calendar-search:before { content: "\f9a5"; }
.i-tim-calendar-settings:before { content: "\f9a6"; }
.i-tim-calendar-time:before { content: "\f9a7"; }
.i-tim-calendar-trash:before { content: "\f9a8"; }
.i-tim-calendar-warning:before { content: "\f9a9"; }
.i-tim-calendar:before { content: "\f9aa"; }
.i-tim-carriage-clock:before { content: "\f9ab"; }
.i-tim-clock-face:before { content: "\f9ac"; }
.i-tim-clock:before { content: "\f9ad"; }
.i-tim-digital-clock:before { content: "\f9ae"; }
.i-tim-digital-time:before { content: "\f9af"; }
.i-tim-metronome:before { content: "\f9b0"; }
.i-tim-minus:before { content: "\f9b1"; }
.i-tim-past:before { content: "\f9b2"; }
.i-tim-pocket-watch-chain:before { content: "\f9b3"; }
.i-tim-pocket-watch:before { content: "\f9b4"; }
.i-tim-sand-timer-end:before { content: "\f9b5"; }
.i-tim-sand-timer-start:before { content: "\f9b6"; }
.i-tim-sand-timer:before { content: "\f9b7"; }
.i-tim-stop-watch:before { content: "\f9b8"; }
.i-tim-sun-dial:before { content: "\f9b9"; }
.i-tim-swiss-clock:before { content: "\f9ba"; }
.i-tim-wrist-watch-square:before { content: "\f9bb"; }
.i-tim-wrist-watch:before { content: "\f9bc"; }
.i-tim-zone-minus-1:before { content: "\f9bd"; }
.i-tim-zone-minus-10:before { content: "\f9be"; }
.i-tim-zone-minus-11:before { content: "\f9bf"; }
.i-tim-zone-minus-12:before { content: "\f9c0"; }
.i-tim-zone-minus-2:before { content: "\f9c1"; }
.i-tim-zone-minus-3:before { content: "\f9c2"; }
.i-tim-zone-minus-4:before { content: "\f9c3"; }
.i-tim-zone-minus-5:before { content: "\f9c4"; }
.i-tim-zone-minus-6:before { content: "\f9c5"; }
.i-tim-zone-minus-7:before { content: "\f9c6"; }
.i-tim-zone-minus-8:before { content: "\f9c7"; }
.i-tim-zone-minus-9:before { content: "\f9c8"; }
.i-tim-zone-plus-1:before { content: "\f9c9"; }
.i-tim-zone-plus-10:before { content: "\f9ca"; }
.i-tim-zone-plus-11:before { content: "\f9cb"; }
.i-tim-zone-plus-12:before { content: "\f9cc"; }
.i-tim-zone-plus-2:before { content: "\f9cd"; }
.i-tim-zone-plus-3:before { content: "\f9ce"; }
.i-tim-zone-plus-4:before { content: "\f9cf"; }
.i-tim-zone-plus-5:before { content: "\f9d0"; }
.i-tim-zone-plus-6:before { content: "\f9d1"; }
.i-tim-zone-plus-7:before { content: "\f9d2"; }
.i-tim-zone-plus-8:before { content: "\f9d3"; }
.i-tim-zone-plus-9:before { content: "\f9d4"; }
.i-tra-airbag:before { content: "\f9d5"; }
.i-tra-battery:before { content: "\f9d6"; }
.i-tra-boat-propeller:before { content: "\f9d7"; }
.i-tra-car-lock:before { content: "\f9d8"; }
.i-tra-door-lock:before { content: "\f9d9"; }
.i-tra-door-open:before { content: "\f9da"; }
.i-tra-drive:before { content: "\f9db"; }
.i-tra-electric-pump:before { content: "\f9dc"; }
.i-tra-engine:before { content: "\f9dd"; }
.i-tra-foglights-dipped:before { content: "\f9de"; }
.i-tra-foglights:before { content: "\f9df"; }
.i-tra-gears:before { content: "\f9e0"; }
.i-tra-helmet-2:before { content: "\f9e1"; }
.i-tra-helmet:before { content: "\f9e2"; }
.i-tra-ignition:before { content: "\f9e3"; }
.i-tra-l-plate:before { content: "\f9e4"; }
.i-tra-lights-dipped:before { content: "\f9e5"; }
.i-tra-lights:before { content: "\f9e6"; }
.i-tra-oil:before { content: "\f9e7"; }
.i-tra-p-plate:before { content: "\f9e8"; }
.i-tra-petrol-pump:before { content: "\f9e9"; }
.i-tra-plane-propeller:before { content: "\f9ea"; }
.i-tra-r-plate:before { content: "\f9eb"; }
.i-tra-road:before { content: "\f9ec"; }
.i-tra-route-66:before { content: "\f9ed"; }
.i-tra-route:before { content: "\f9ee"; }
.i-tra-screen-wash:before { content: "\f9ef"; }
.i-tra-screen-wipe:before { content: "\f9f0"; }
.i-tra-seatbelt:before { content: "\f9f1"; }
.i-tra-sidelights:before { content: "\f9f2"; }
.i-tra-speed-camera:before { content: "\f9f3"; }
.i-tra-speedo:before { content: "\f9f4"; }
.i-tra-steer:before { content: "\f9f5"; }
.i-tra-taxi:before { content: "\f9f6"; }
.i-tra-traction:before { content: "\f9f7"; }
.i-tra-traffic-lights:before { content: "\f9f8"; }
.i-tra-train-track:before { content: "\f9f9"; }
.i-tra-warning:before { content: "\f9fa"; }
.i-tra-wheel-plane:before { content: "\f9fb"; }
.i-typ-align-centre:before { content: "\f9fc"; }
.i-typ-align-left:before { content: "\f9fd"; }
.i-typ-align-right:before { content: "\f9fe"; }
.i-typ-area:before { content: "\f9ff"; }
.i-typ-baseline:before { content: "\fa00"; }
.i-typ-bold:before { content: "\fa01"; }
.i-typ-bullets-numbers:before { content: "\fa02"; }
.i-typ-bullets-roman:before { content: "\fa03"; }
.i-typ-bullets:before { content: "\fa04"; }
.i-typ-caps-all:before { content: "\fa05"; }
.i-typ-caps-small:before { content: "\fa06"; }
.i-typ-case:before { content: "\fa07"; }
.i-typ-columns-three:before { content: "\fa08"; }
.i-typ-columns-two:before { content: "\fa09"; }
.i-typ-cross:before { content: "\fa0a"; }
.i-typ-cursor:before { content: "\fa0b"; }
.i-typ-height:before { content: "\fa0c"; }
.i-typ-indent:before { content: "\fa0d"; }
.i-typ-italic-2:before { content: "\fa0e"; }
.i-typ-italic:before { content: "\fa0f"; }
.i-typ-justified-centre:before { content: "\fa10"; }
.i-typ-justified-left:before { content: "\fa11"; }
.i-typ-justified-right:before { content: "\fa12"; }
.i-typ-justified:before { content: "\fa13"; }
.i-typ-ligatures:before { content: "\fa14"; }
.i-typ-line-height:before { content: "\fa15"; }
.i-typ-list-2:before { content: "\fa16"; }
.i-typ-list:before { content: "\fa17"; }
.i-typ-minus:before { content: "\fa18"; }
.i-typ-paragraph:before { content: "\fa19"; }
.i-typ-path-perpendicular:before { content: "\fa1a"; }
.i-typ-path:before { content: "\fa1b"; }
.i-typ-plus:before { content: "\fa1c"; }
.i-typ-rotate:before { content: "\fa1d"; }
.i-typ-sans-serif:before { content: "\fa1e"; }
.i-typ-secure:before { content: "\fa1f"; }
.i-typ-serif:before { content: "\fa20"; }
.i-typ-settings:before { content: "\fa21"; }
.i-typ-shape:before { content: "\fa22"; }
.i-typ-size-decrease:before { content: "\fa23"; }
.i-typ-size-increase:before { content: "\fa24"; }
.i-typ-spacing-bottom:before { content: "\fa25"; }
.i-typ-spacing-left:before { content: "\fa26"; }
.i-typ-spacing-letter:before { content: "\fa27"; }
.i-typ-spacing-line:before { content: "\fa28"; }
.i-typ-spacing-right:before { content: "\fa29"; }
.i-typ-spacing-top:before { content: "\fa2a"; }
.i-typ-stretch:before { content: "\fa2b"; }
.i-typ-strike-2:before { content: "\fa2c"; }
.i-typ-strike:before { content: "\fa2d"; }
.i-typ-subscript-2:before { content: "\fa2e"; }
.i-typ-subscript:before { content: "\fa2f"; }
.i-typ-superscript-2:before { content: "\fa30"; }
.i-typ-superscript:before { content: "\fa31"; }
.i-typ-target:before { content: "\fa32"; }
.i-typ-text:before { content: "\fa33"; }
.i-typ-tick:before { content: "\fa34"; }
.i-typ-underline-2:before { content: "\fa35"; }
.i-typ-underline:before { content: "\fa36"; }
.i-typ-width:before { content: "\fa37"; }
.i-use-connect:before { content: "\fa38"; }
.i-use-fem-chat:before { content: "\fa39"; }
.i-use-fem-cross:before { content: "\fa3a"; }
.i-use-fem-edit:before { content: "\fa3b"; }
.i-use-fem-fem-fem:before { content: "\fa3c"; }
.i-use-fem-fem-male:before { content: "\fa3d"; }
.i-use-fem-fem:before { content: "\fa3e"; }
.i-use-fem-location:before { content: "\fa3f"; }
.i-use-fem-male:before { content: "\fa40"; }
.i-use-fem-minus:before { content: "\fa41"; }
.i-use-fem-plus:before { content: "\fa42"; }
.i-use-fem-reply:before { content: "\fa43"; }
.i-use-fem-script:before { content: "\fa44"; }
.i-use-fem-search:before { content: "\fa45"; }
.i-use-fem-secure:before { content: "\fa46"; }
.i-use-fem-settings:before { content: "\fa47"; }
.i-use-fem-tick:before { content: "\fa48"; }
.i-use-fem-warning:before { content: "\fa49"; }
.i-use-fem:before { content: "\fa4a"; }
.i-use-male-chat:before { content: "\fa4b"; }
.i-use-male-cross:before { content: "\fa4c"; }
.i-use-male-edit:before { content: "\fa4d"; }
.i-use-male-fem:before { content: "\fa4e"; }
.i-use-male-location:before { content: "\fa4f"; }
.i-use-male-male-fem:before { content: "\fa50"; }
.i-use-male-male-male:before { content: "\fa51"; }
.i-use-male-male:before { content: "\fa52"; }
.i-use-male-minus:before { content: "\fa53"; }
.i-use-male-plus:before { content: "\fa54"; }
.i-use-male-reply:before { content: "\fa55"; }
.i-use-male-script:before { content: "\fa56"; }
.i-use-male-search:before { content: "\fa57"; }
.i-use-male-secure:before { content: "\fa58"; }
.i-use-male-settings:before { content: "\fa59"; }
.i-use-male-tick:before { content: "\fa5a"; }
.i-use-male-warning:before { content: "\fa5b"; }
.i-use-male:before { content: "\fa5c"; }
.i-use-rotate:before { content: "\fa5d"; }
.i-use-structure:before { content: "\fa5e"; }
.i-use-switch:before { content: "\fa5f"; }
.i-use-user-chat:before { content: "\fa60"; }
.i-use-user-connect:before { content: "\fa61"; }
.i-use-user-cross:before { content: "\fa62"; }
.i-use-user-edit:before { content: "\fa63"; }
.i-use-user-location:before { content: "\fa64"; }
.i-use-user-minus:before { content: "\fa65"; }
.i-use-user-plus:before { content: "\fa66"; }
.i-use-user-reply:before { content: "\fa67"; }
.i-use-user-rotate:before { content: "\fa68"; }
.i-use-user-script:before { content: "\fa69"; }
.i-use-user-search:before { content: "\fa6a"; }
.i-use-user-secure:before { content: "\fa6b"; }
.i-use-user-settings:before { content: "\fa6c"; }
.i-use-user-structure:before { content: "\fa6d"; }
.i-use-user-switch:before { content: "\fa6e"; }
.i-use-user-tick:before { content: "\fa6f"; }
.i-use-user-user-user:before { content: "\fa70"; }
.i-use-user-user:before { content: "\fa71"; }
.i-use-user-warning:before { content: "\fa72"; }
.i-use-user:before { content: "\fa73"; }
.i-veh-4x4:before { content: "\fa74"; }
.i-veh-airship:before { content: "\fa75"; }
.i-veh-balloon:before { content: "\fa76"; }
.i-veh-bicycle:before { content: "\fa77"; }
.i-veh-biplane:before { content: "\fa78"; }
.i-veh-boat:before { content: "\fa79"; }
.i-veh-bus:before { content: "\fa7a"; }
.i-veh-cablecar:before { content: "\fa7b"; }
.i-veh-camper:before { content: "\fa7c"; }
.i-veh-car:before { content: "\fa7d"; }
.i-veh-caravan:before { content: "\fa7e"; }
.i-veh-cargo:before { content: "\fa7f"; }
.i-veh-coach:before { content: "\fa80"; }
.i-veh-helicopter:before { content: "\fa81"; }
.i-veh-lorry-2:before { content: "\fa82"; }
.i-veh-lorry:before { content: "\fa83"; }
.i-veh-mono:before { content: "\fa84"; }
.i-veh-monoplane-2:before { content: "\fa85"; }
.i-veh-monoplane-3:before { content: "\fa86"; }
.i-veh-monoplane:before { content: "\fa87"; }
.i-veh-moped-2:before { content: "\fa88"; }
.i-veh-moped:before { content: "\fa89"; }
.i-veh-motorbike-2:before { content: "\fa8a"; }
.i-veh-motorbike:before { content: "\fa8b"; }
.i-veh-mountain:before { content: "\fa8c"; }
.i-veh-penny-farthing:before { content: "\fa8d"; }
.i-veh-pickup:before { content: "\fa8e"; }
.i-veh-plane-2:before { content: "\fa8f"; }
.i-veh-plane:before { content: "\fa90"; }
.i-veh-police:before { content: "\fa91"; }
.i-veh-road:before { content: "\fa92"; }
.i-veh-sailboat-2:before { content: "\fa93"; }
.i-veh-sailboat:before { content: "\fa94"; }
.i-veh-scooter:before { content: "\fa95"; }
.i-veh-seaplane:before { content: "\fa96"; }
.i-veh-sedgeway-2:before { content: "\fa97"; }
.i-veh-sedgeway:before { content: "\fa98"; }
.i-veh-ship-2:before { content: "\fa99"; }
.i-veh-ship:before { content: "\fa9a"; }
.i-veh-steamtrain:before { content: "\fa9b"; }
.i-veh-submarine:before { content: "\fa9c"; }
.i-veh-taxi:before { content: "\fa9d"; }
.i-veh-train-2:before { content: "\fa9e"; }
.i-veh-train:before { content: "\fa9f"; }
.i-veh-tram:before { content: "\faa0"; }
.i-veh-truck-2:before { content: "\faa1"; }
.i-veh-truck:before { content: "\faa2"; }
.i-veh-tuk-tuk:before { content: "\faa3"; }
.i-veh-underground:before { content: "\faa4"; }
.i-veh-van:before { content: "\faa5"; }
.i-wea-bolt:before { content: "\faa6"; }
.i-wea-celsius:before { content: "\faa7"; }
.i-wea-cloud:before { content: "\faa8"; }
.i-wea-clouds:before { content: "\faa9"; }
.i-wea-day-lightning-2:before { content: "\faaa"; }
.i-wea-day-lightning-snow:before { content: "\faab"; }
.i-wea-day-lightning:before { content: "\faac"; }
.i-wea-day-rain-2:before { content: "\faad"; }
.i-wea-day-rain-3:before { content: "\faae"; }
.i-wea-day-rain:before { content: "\faaf"; }
.i-wea-day-snow-2:before { content: "\fab0"; }
.i-wea-day-snow-3:before { content: "\fab1"; }
.i-wea-day-snow:before { content: "\fab2"; }
.i-wea-day-wind:before { content: "\fab3"; }
.i-wea-day:before { content: "\fab4"; }
.i-wea-fahrenheit:before { content: "\fab5"; }
.i-wea-flake:before { content: "\fab6"; }
.i-wea-lightning-2:before { content: "\fab7"; }
.i-wea-lightning-snow:before { content: "\fab8"; }
.i-wea-lightning:before { content: "\fab9"; }
.i-wea-moon:before { content: "\faba"; }
.i-wea-night-lightning-2:before { content: "\fabb"; }
.i-wea-night-lightning-snow:before { content: "\fabc"; }
.i-wea-night-lightning:before { content: "\fabd"; }
.i-wea-night-rain-2:before { content: "\fabe"; }
.i-wea-night-rain-3:before { content: "\fabf"; }
.i-wea-night-rain:before { content: "\fac0"; }
.i-wea-night-snow-2:before { content: "\fac1"; }
.i-wea-night-snow-3:before { content: "\fac2"; }
.i-wea-night-snow:before { content: "\fac3"; }
.i-wea-night-wind:before { content: "\fac4"; }
.i-wea-night:before { content: "\fac5"; }
.i-wea-pin-wheel:before { content: "\fac6"; }
.i-wea-rain-2:before { content: "\fac7"; }
.i-wea-rain-3:before { content: "\fac8"; }
.i-wea-rain-umbrella:before { content: "\fac9"; }
.i-wea-rain:before { content: "\faca"; }
.i-wea-rainbow:before { content: "\facb"; }
.i-wea-snow-2:before { content: "\facc"; }
.i-wea-snow-3:before { content: "\facd"; }
.i-wea-snow:before { content: "\face"; }
.i-wea-sun:before { content: "\facf"; }
.i-wea-temp-day:before { content: "\fad0"; }
.i-wea-temp-high:before { content: "\fad1"; }
.i-wea-temp-low:before { content: "\fad2"; }
.i-wea-temp-med:before { content: "\fad3"; }
.i-wea-temp-minus:before { content: "\fad4"; }
.i-wea-temp-night:before { content: "\fad5"; }
.i-wea-temp-plus:before { content: "\fad6"; }
.i-wea-tornado:before { content: "\fad7"; }
.i-wea-umbrella:before { content: "\fad8"; }
.i-wea-vane:before { content: "\fad9"; }
.i-wea-wind-2:before { content: "\fada"; }
.i-wea-wind-umbrella:before { content: "\fadb"; }
.i-wea-wind:before { content: "\fadc"; }
.i-wea-windsock:before { content: "\fadd"; }

